import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="border-t border-gray-200">
      <div className="max-w-[1080px] mx-auto px-4 md:px-8 py-8 md:py-12">
        <div className="flex flex-col md:flex-row justify-between gap-6">
          <div className="max-w-md">
            <p className="text-sm text-gray-500">
              © {new Date().getFullYear()} DMV Plus. All rights reserved.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              DMV Plus is not affiliated with any government agency. This is a third-party service designed to assist users with DMV-related information and services.
            </p>
          </div>
          <div className="flex flex-wrap gap-6">
            <Link 
              to="/admin"
              className="text-sm text-gray-500 hover:text-gray-900 transition-colors"
            >
              Admin Dashboard
            </Link>
            <a 
              href="https://driving-tests.org/terms-of-use/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-sm text-gray-500 hover:text-gray-900 transition-colors"
            >
              Privacy Policy
            </a>
            <a 
              href="https://driving-tests.org/terms-of-use/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-sm text-gray-500 hover:text-gray-900 transition-colors"
            >
              Terms of Service
            </a>
            <a 
              href="mailto:info@driving-tests.org" 
              className="text-sm text-gray-500 hover:text-gray-900 transition-colors"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}