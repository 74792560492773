import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bot, User, RefreshCw, GitBranch, FileText, Car, Key, Shield, CheckCircle2, Calendar, Volume2, ThumbsUp, ThumbsDown, Loader2 } from 'lucide-react';

interface Message {
  role: 'user' | 'assistant';
  content: string;
  status?: 'typing' | 'complete';
  isChecklist?: boolean;
  items?: { icon: React.ElementType; text: string; }[];
  showDiagram?: boolean;
  showAudio?: boolean;
  showFeedback?: boolean;
}

const INITIAL_CONVERSATION: Message[] = [
  { role: 'user', content: "How do I get a driver's license in California?" },
  { 
    role: 'assistant', 
    content: "I'll help you with getting a California driver's license. Here's what you'll need:",
    showAudio: true,
    status: 'complete'
  },
  { 
    role: 'assistant', 
    content: "Required Documents Checklist:",
    isChecklist: true,
    items: [
      { icon: FileText, text: "Proof of identity (passport or birth certificate)" },
      { icon: Car, text: "Valid Social Security number" },
      { icon: Key, text: "California residency document" },
      { icon: Shield, text: "Completed DL 44 form" },
      { icon: Calendar, text: "Payment for fees" }
    ]
  },
  { role: 'user', content: "Do I need to schedule an appointment?" },
  { 
    role: 'assistant', 
    content: "Yes, I recommend scheduling an appointment to avoid long wait times. Here's a visual guide to the process:",
    showDiagram: true,
    showAudio: true,
    showFeedback: true,
    status: 'complete'
  }
];

export function ExampleChat() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isComplete, setIsComplete] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const intervalRef = useRef<number>();
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isPlaying) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      return;
    }

    let currentIndex = 0;
    setMessages([INITIAL_CONVERSATION[0]]); // Start with first message
    
    intervalRef.current = window.setInterval(() => {
      if (currentIndex < INITIAL_CONVERSATION.length - 1) {
        currentIndex++;
        setMessages(prev => {
          const newMessages = [...prev, INITIAL_CONVERSATION[currentIndex]];
          return newMessages;
        });
      } else {
        setIsComplete(true);
        setIsPlaying(false);
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, 2000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPlaying]);

  const handleReplay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setMessages([INITIAL_CONVERSATION[0]]);
    setIsComplete(false);
    setIsPlaying(true);
  };

  return (
    <div className="relative bg-white/90 backdrop-blur-xl rounded-3xl border border-gray-200/50 h-[480px] shadow-xl hover:shadow-2xl transition-all duration-500">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 px-6 py-4 bg-white/95 backdrop-blur-xl border-b border-gray-200/50 rounded-t-3xl">
        <div className="flex items-center gap-3">
          <div className="flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-xl bg-gradient-to-br from-blue-500/5 to-blue-600/5 text-blue-500">
            <Bot size={16} />
          </div>
          <div>
            <div className="text-sm font-medium text-gray-900">DMV Plus Assistant</div>
            <div className="text-xs text-gray-500">Always here to help</div>
          </div>
          {isComplete && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleReplay}
              className="ml-auto p-2 rounded-xl text-gray-400 hover:text-gray-600 hover:bg-gray-100/50 transition-colors"
            >
              <RefreshCw size={16} />
            </motion.button>
          )}
        </div>
      </div>

      {/* Messages */}
      <div
        className="h-[calc(100%-73px)] px-6 overflow-y-auto scroll-smooth"
      >
        <div className="py-4 space-y-4">
          <AnimatePresence mode="popLayout">
            {messages.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
                className={`flex items-start gap-3 ${
                  message.role === 'assistant' ? 'pr-12' : 'pl-12'
                }`}
              >
                {message.role === 'assistant' && (
                  <div className="flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-xl bg-gradient-to-br from-blue-500/5 to-blue-600/5 text-blue-500">
                    <Bot size={16} />
                  </div>
                )}
                <div className="flex-1">
                  {message.isChecklist ? (
                    <div className="space-y-2 bg-white/80 rounded-2xl p-3 shadow-sm border border-gray-100/50">
                      <div className="font-medium text-gray-800">{message.content}</div>
                      {message.items?.map((item, i) => (
                        <motion.div
                          key={i} 
                          initial={{ opacity: 0, x: -10 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: i * 0.1 }}
                          className="flex items-center gap-2 text-gray-600"
                        >
                          <div className={`w-6 h-6 rounded-lg bg-gray-50 flex items-center justify-center flex-shrink-0 group-hover:scale-110 transition-transform duration-200`}>
                            <item.icon size={14} className={`text-gray-600`} />
                          </div>
                          <span className="text-sm">{item.text}</span>
                        </motion.div>
                      ))}
                    </div>
                  ) : (
                    <div className={`relative rounded-2xl px-4 py-2.5 ${
                      message.role === 'assistant'
                        ? 'bg-white/80 text-gray-800 shadow-sm border border-gray-100/50'
                        : 'bg-blue-500/5 text-blue-900'
                    } ${message.status === 'typing' ? 'animate-pulse' : ''}`}>
                      {message.role === 'assistant' && message.showAudio && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          className="absolute -top-2 -right-2 p-1.5 rounded-lg bg-blue-50/80 text-blue-600 backdrop-blur-sm"
                        >
                          <Volume2 size={12} />
                        </motion.div>
                      )}
                      <span className="text-sm">{message.content}</span>
                      {message.status === 'typing' && (
                        <motion.div 
                          className="flex items-center gap-1 mt-1"
                          animate={{ opacity: [0.5, 1, 0.5] }}
                          transition={{ duration: 1.5, repeat: Infinity }}
                        >
                          <Loader2 size={12} className="animate-spin text-gray-400" />
                          <span className="text-xs text-gray-400">typing...</span>
                        </motion.div>
                      )}
                      {(message.showDiagram || message.showFeedback) && (
                      <div className="flex items-center gap-2 mt-2">
                        {message.showDiagram && (
                          <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                            className="inline-flex items-center gap-2 px-3 py-1.5 text-xs bg-blue-50/80 text-blue-600 rounded-lg hover:bg-blue-100/80 transition-colors backdrop-blur-sm"
                          >
                            <GitBranch size={12} />
                            View Process Diagram
                          </motion.button>
                        )}
                        {message.showFeedback && message.status === 'complete' && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                            className="flex items-center gap-1"
                          >
                            <button className="p-1 rounded-lg text-gray-400 hover:text-gray-600">
                              <ThumbsUp size={12} />
                            </button>
                            <button className="p-1 rounded-lg text-gray-400 hover:text-gray-600">
                              <ThumbsDown size={12} />
                            </button>
                          </motion.div>
                        )}
                      </div>)}
                    </div>
                  )}
                </div>
                {message.role === 'user' && (
                  <div className="flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-xl bg-gradient-to-br from-gray-900/5 to-gray-800/5 text-gray-700">
                    <User size={12} className="text-gray-500" />
                  </div>
                )}
              </motion.div>
            ))}
          </AnimatePresence>
          <div ref={scrollRef} />
        </div>
      </div>
    </div>
  );
}