import React from 'react';
import { Check } from 'lucide-react';
import type { ChecklistItem as ChecklistItemType } from '@/types';

interface ChecklistItemProps {
  item: ChecklistItemType;
  onToggle: (id: string) => void;
}

export function ChecklistItem({ item, onToggle }: ChecklistItemProps) {
  return (
    <div className="group rounded-lg border border-gray-200/50 dark:border-gray-800/50 bg-white dark:bg-gray-900 overflow-hidden transition-colors">
      <div
        onClick={() => onToggle(item.id)}
        className="w-full p-3 text-left cursor-pointer"
      >
        <div className="flex items-start gap-3">
          <div
            className={`flex h-5 w-5 shrink-0 items-center justify-center rounded transition-colors ${
              item.isCompleted
                ? 'bg-green-600 dark:bg-green-500 text-white'
                : 'border border-gray-300/50 dark:border-gray-600/50 hover:border-gray-400 dark:hover:border-gray-500'
            }`}
          >
            {item.isCompleted && <Check size={14} />}
          </div>
          <div className="flex-1">
            <span className={`text-sm ${
              item.isCompleted 
                ? 'text-gray-500 dark:text-gray-400 line-through' 
                : 'text-gray-900 dark:text-gray-100'
            }`}
            >
              {item.text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}