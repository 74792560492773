import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Clock, FileQuestion, Calendar, AlertCircle, CheckCircle2 } from 'lucide-react';

export function PainPoints() {
  const painPoints = [
    {
      icon: Clock,
      title: "No More Long Waits",
      problem: "Spending hours in DMV lines",
      solution: "Get instant answers and guidance 24/7",
      color: "blue"
    },
    {
      icon: FileQuestion,
      title: "Clear Instructions",
      problem: "Confusing procedures and requirements",
      solution: "Step-by-step guidance in plain language",
      color: "green"
    },
    {
      icon: Calendar,
      title: "Easy Planning",
      problem: "Limited appointment availability",
      solution: "Smart scheduling recommendations",
      color: "purple"
    },
    {
      icon: AlertCircle,
      title: "Reliable Information",
      problem: "Inconsistent or outdated guidance",
      solution: "Always up-to-date, state-specific info",
      color: "orange"
    }
  ];

  return (
    <div className="relative py-24 overflow-hidden">
      {/* Animated Background Gradient */}
      <motion.div 
        className="absolute inset-0 bg-gradient-to-b from-gray-50/50 to-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      />

      <div className="relative max-w-[1080px] mx-auto px-4 md:px-8">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <motion.span 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="inline-block px-4 py-2 bg-blue-50 text-blue-600 rounded-full text-sm font-medium mb-4"
            >
              Why DMV Plus?
            </motion.span>
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-3xl font-semibold text-gray-900 mb-4"
            >
              Say Goodbye to DMV Frustrations
            </motion.h2>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="text-xl text-gray-600"
            >
              We've solved the most common DMV challenges
            </motion.p>
          </motion.div>
        </div>

        <div className="grid md:grid-cols-2 gap-6 md:gap-8">
          {painPoints.map((point, index) => (
            <motion.div
              key={point.title}
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                delay: index * 0.2,
                duration: 0.5,
                type: "spring",
                stiffness: 100 
              }}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2 } 
              }}
              className={`relative p-8 bg-gradient-to-br from-${point.color}-50 to-white rounded-3xl border border-${point.color}-100/50 overflow-hidden group hover:shadow-lg transition-all duration-300`}
            >
              {/* Animated Background Blob */}
              <motion.div 
                className={`absolute -right-16 -bottom-16 w-32 h-32 bg-${point.color}-500/10 rounded-full blur-2xl`}
                animate={{ 
                  scale: [1, 1.2, 1],
                  opacity: [0.5, 0.3, 0.5] 
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              {/* Content */}
              <div className="relative">
                <motion.div 
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ 
                    delay: index * 0.2 + 0.3,
                    type: "spring",
                    stiffness: 200
                  }}
                  className={`inline-flex p-3 rounded-2xl bg-${point.color}-100/50 text-${point.color}-600 mb-6`}
                >
                  <motion.div
                    animate={{ 
                      rotate: [0, 360],
                    }}
                    transition={{ 
                      duration: 20,
                      repeat: Infinity,
                      ease: "linear"
                    }}
                  >
                    <point.icon size={24} />
                  </motion.div>
                </motion.div>

                <motion.h3 
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 + 0.4 }}
                  className="text-xl font-semibold text-gray-900 mb-4"
                >
                  {point.title}
                </motion.h3>

                <div className="space-y-4">
                  {/* Problem */}
                  <motion.div 
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.2 + 0.5 }}
                    className="flex items-start gap-3"
                  >
                    <motion.div 
                      animate={{ 
                        scale: [1, 1.1, 1],
                        rotate: [0, 5, -5, 0]
                      }}
                      transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        repeatDelay: 3
                      }}
                      className="w-6 h-6 rounded-full bg-red-100/50 flex items-center justify-center flex-shrink-0 mt-0.5"
                    >
                      <span className="text-red-600 text-lg">×</span>
                    </motion.div>
                    <p className="text-gray-600">
                      {point.problem}
                    </p>
                  </motion.div>

                  {/* Solution */}
                  <motion.div 
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.2 + 0.6 }}
                    className="flex items-start gap-3"
                  >
                    <motion.div 
                      animate={{ 
                        scale: [1, 1.2, 1],
                      }}
                      transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        repeatDelay: 3
                      }}
                      className="w-6 h-6 rounded-full bg-green-100/50 flex items-center justify-center flex-shrink-0 mt-0.5"
                    >
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ 
                          delay: index * 0.2 + 0.7,
                          type: "spring",
                          stiffness: 200
                        }}
                      >
                        <CheckCircle2 size={14} className="text-green-600" />
                      </motion.div>
                    </motion.div>
                    <p className="text-gray-900 font-medium">
                      {point.solution}
                    </p>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}