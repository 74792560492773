import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Clock4, DollarSign, Frown, Timer, Clock } from 'lucide-react';

interface Statistic {
  icon: React.ElementType;
  title: string;
  description: string;
  color: string;
  emphasis?: string;
}

export function StatisticsCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const statistics: Statistic[] = [
    {
      icon: Clock4,
      title: "Americans collectively spend over",
      emphasis: "400,000 hours",
      description: "daily waiting at the DMV. Our AI DMV Assistant is here to give you that time back.",
      color: "blue"
    },
    {
      icon: DollarSign,
      title: "DMV inefficiency costs Americans",
      emphasis: "millions of dollars",
      description: "in lost time each year",
      color: "red"
    },
    {
      icon: Frown,
      title: "Skip the DMV, not your life:",
      emphasis: "Over 50%",
      description: "of DMV users report extreme frustration during visits",
      color: "orange"
    },
    {
      icon: Timer,
      title: "You're more likely to",
      emphasis: "win a small lottery prize",
      description: "than to leave the DMV in under 30 minutes",
      color: "yellow"
    },
    {
      icon: Clock,
      title: "DMV:",
      emphasis: "The only place",
      description: "where an hour feels like a lifetime",
      color: "purple"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % statistics.length);
    }, 8000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative overflow-hidden py-8 md:py-12 bg-gradient-to-b from-transparent via-gray-50/30 to-transparent">
      <div className="w-full max-w-[1080px] mx-auto px-4 md:px-8">
        <div className="relative h-[220px] sm:h-[180px] md:h-[200px] mb-12 md:mb-16">
          <AnimatePresence mode="wait">
            {statistics.map((stat, index) => index === currentIndex && (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.7 }}
                className="absolute inset-0 w-full"
              >
                <div className="text-center">
                  <motion.div
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className={`inline-flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium mb-4 md:mb-6 bg-${stat.color}-50/80 text-${stat.color}-600 backdrop-blur-sm`}
                  >
                    <stat.icon size={16} className="animate-pulse [animation-duration:2s]" />
                    <span>Did you know?</span>
                  </motion.div>
                  
                  <div className="space-y-3 md:space-y-4 px-4 md:px-0">
                    <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-900 leading-tight">
                      {stat.title}{' '}
                      {stat.emphasis && (
                        <span className={`text-${stat.color}-600 font-bold`}>
                          {stat.emphasis}
                        </span>
                      )}
                    </h3>
                    <p className="text-lg sm:text-xl text-gray-600 leading-snug">
                      {stat.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>

          {/* Navigation Dots */}
          <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 flex items-center gap-2">
            {statistics.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-500 ${
                  index === currentIndex
                    ? 'w-6 bg-blue-600/80'
                    : 'bg-gray-300/50 hover:bg-gray-400/60'
                }`}
                aria-label={`Go to statistic ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}