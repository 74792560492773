import React, { useState, useEffect, useRef } from 'react';
import mermaid from 'mermaid';
import { motion } from 'framer-motion';
import { X, Maximize2, Minimize2, Download, AlertCircle } from 'lucide-react';

// Initialize mermaid with base configuration
mermaid.initialize({
  startOnLoad: false,
  theme: 'base',
  securityLevel: 'strict',
  logLevel: 'error',
  fontFamily: 'inherit',
  fontSize: 14,
  flowchart: {
    curve: 'basis',
    padding: 20,
    nodeSpacing: 50,
    rankSpacing: 50,
    htmlLabels: true,
    useMaxWidth: true
  }
});

interface DiagramViewerProps {
  diagram: string;
  isOpen: boolean;
  onClose: () => void;
}

export function DiagramViewer({ diagram, isOpen, onClose }: DiagramViewerProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleThemeChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
      if (containerRef.current && diagram) {
        renderDiagram();
      }
    };

    setIsDarkMode(darkModeMediaQuery.matches);
    darkModeMediaQuery.addEventListener('change', handleThemeChange);
    return () => darkModeMediaQuery.removeEventListener('change', handleThemeChange);
  }, [diagram]);

  useEffect(() => {
    if (!isOpen || !diagram) return;
    renderDiagram();
  }, [diagram, isOpen, isDarkMode]);

  const renderDiagram = async () => {
    try {
      if (!containerRef.current) return;
      
      // Clear previous content and error
      containerRef.current.innerHTML = '';
      setError(null);

      // Configure mermaid based on current theme
      const config = {
        theme: isDarkMode ? 'dark' : 'base',
        themeVariables: {
          primaryColor: isDarkMode ? '#1e293b' : '#f8fafc',
          primaryBorderColor: isDarkMode ? '#475569' : '#64748b',
          lineColor: isDarkMode ? '#475569' : '#64748b',
          fontSize: '14px',
          fontFamily: 'inherit'
        }
      };

      await mermaid.initialize(config);

      const id = `mermaid-${Date.now()}`;
      const { svg } = await mermaid.render(id, diagram.trim());
      
      containerRef.current.innerHTML = svg;
      
      const svgElement = containerRef.current.querySelector('svg');
      if (svgElement) {
        svgElement.classList.add('dark:filter', 'dark:brightness-95');
        svgElement.setAttribute('width', '100%');
        svgElement.setAttribute('height', 'auto');
        svgElement.style.maxWidth = '100%';
        svgElement.style.minHeight = '300px';
        svgElement.style.margin = '0 auto';
      }
    } catch (error) {
      console.error('Error rendering diagram:', error);
      setError('Failed to render the diagram. Please try again.');
    }
  };

  const handleDownload = () => {
    if (!containerRef.current) return;
    
    const svg = containerRef.current.querySelector('svg');
    if (!svg) return;
    
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = 'process-diagram.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm ${
        isFullscreen ? 'overflow-hidden' : 'overflow-y-auto'
      }`}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className={`relative bg-white dark:bg-gray-800 rounded-xl shadow-xl ${
          isFullscreen
            ? 'fixed inset-0 m-0 rounded-none'
            : 'w-full max-w-3xl mx-auto'
        }`}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Process Diagram
          </h3>
          <div className="flex items-center gap-2">
            <button
              onClick={handleDownload}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg transition-colors"
              title="Download SVG"
            >
              <Download size={20} />
            </button>
            <button
              onClick={() => setIsFullscreen(!isFullscreen)}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg transition-colors"
            >
              {isFullscreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </button>
            <button
              onClick={onClose}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg transition-colors"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        <div
          className={`overflow-auto bg-white dark:bg-gray-800 ${
            isFullscreen ? 'h-[calc(100vh-73px)]' : 'max-h-[70vh]'
          }`}
        >
          <div className="p-6">
            {error ? (
              <div className="flex items-center gap-2 text-red-500 dark:text-red-400">
                <AlertCircle size={20} />
                <span>{error}</span>
              </div>
            ) : (
              <div
                ref={containerRef}
                className="mermaid-diagram overflow-x-auto"
              />
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}