import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare } from 'lucide-react';
import { ExampleChat } from './ExampleChat';

interface HeroProps {
  onDismiss: () => void;
}

export function Hero({ onDismiss }: HeroProps) {
  return (
    <div className="relative min-h-[90vh] flex flex-col-reverse md:flex-row items-center justify-center px-4 md:px-8 py-12 md:py-0 overflow-hidden">
      {/* Background Gradients */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute w-[27%] aspect-square opacity-10 blur-[100px] bg-[#fad92c] -top-[238px] left-[6%]" />
        <div className="absolute w-[42%] aspect-square opacity-10 blur-[100px] bg-gradient-to-r from-[#0057ff] to-[#9dff00] -top-[330px] left-[14%]" />
        <div className="absolute w-[35%] aspect-square opacity-10 blur-[100px] bg-gradient-to-r from-[#7000ff] to-[#ff9dc0] top-[305px] left-[2%]" />
        <div className="absolute w-[27%] aspect-square opacity-10 blur-[100px] bg-[#4cd964] top-[366px] left-[33%]" />
      </div>

      <div className="w-full max-w-[1080px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16">
        {/* Left Column - Text Content */}
        <div className="flex flex-col justify-center text-center md:text-left order-1 md:order-1 mb-8 md:mb-0">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-4xl sm:text-5xl md:text-6xl font-bold text-gray-900 mb-6 tracking-tight"
          >
            Experience the Future of DMV Services Now
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-lg md:text-xl text-gray-600 mb-8 md:mb-12"
          >
            Skip the lines, not the service. Get instant help with all your DMV needs.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="space-y-6"
          >
            <motion.button
              onClick={onDismiss}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full md:w-auto px-8 py-4 bg-blue-600 text-white rounded-2xl text-lg font-medium hover:bg-blue-700 transition-colors shadow-lg hover:shadow-xl flex items-center justify-center gap-2"
            >
              <MessageSquare size={20} />
              Start Chat Assistant
            </motion.button>
            <p className="text-sm text-gray-500">
              No registration required • Available in all 50 states
            </p>
          </motion.div>
        </div>

        {/* Right Column - Chat Demo */}
        <div className="relative flex items-center order-2 md:order-2">
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="w-full relative"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5 rounded-3xl transform -rotate-1" />
            <div className="absolute inset-0 bg-gradient-to-tr from-green-500/5 to-yellow-500/5 rounded-3xl transform rotate-1" />
            <ExampleChat />
          </motion.div>
        </div>
      </div>
    </div>
  );
}