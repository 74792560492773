import React from 'react';
import { MessageSquare, ThumbsUp, ThumbsDown } from 'lucide-react';

interface StatsOverviewProps {
  totalCount: number;
  positivePercentage: number;
}

export function StatsOverview({ totalCount, positivePercentage }: StatsOverviewProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
              Total Feedback
            </p>
            <p className="text-2xl font-semibold text-gray-900 dark:text-white">
              {totalCount}
            </p>
          </div>
          <MessageSquare className="h-8 w-8 text-blue-600 dark:text-blue-400" />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
              Positive Feedback
            </p>
            <p className="text-2xl font-semibold text-green-600">
              {positivePercentage.toFixed(1)}%
            </p>
          </div>
          <ThumbsUp className="h-8 w-8 text-green-600" />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
              Negative Feedback
            </p>
            <p className="text-2xl font-semibold text-red-600">
              {(100 - positivePercentage).toFixed(1)}%
            </p>
          </div>
          <ThumbsDown className="h-8 w-8 text-red-600" />
        </div>
      </div>
    </div>
  );
}