import type { ChatContext, Message } from '../types';

interface ParsedResponse {
  text: string;
  options?: string[];
  isComplete?: boolean;
}

export class StreamParser {
  private buffer: string = '';
  private lastParsedContent: string = '';
  private context: ChatContext;
  private isComplete: boolean = false;

  constructor(initialContext?: ChatContext) {
    this.context = initialContext || {
      previousTopics: [],
      messages: [],
    };
  }

  private extractOptions(content: string): { text: string, options?: string[] } {
    // First try to match the standard Options format
    const optionsMatch = content.match(/\(Options:\s*([^)]+)\)/i);
    if (optionsMatch) {
      const options = optionsMatch[1]
        .split('|')
        .map(s => s.trim())
        .filter(s => s.length > 0);

      // Remove the options text from the content
      const cleanContent = content.replace(/\s*\(Options:[^)]+\)/i, '').trim();
      return { text: cleanContent, options };
    }

    // Try other patterns
    const patterns = [
      /Options:\s*([^\n]+)/i,
      /\(([^)]+\|[^)]+)\)/,
      /(?:choose|select) (?:between|from):\s*([^\n]+)/i,
      /Would you like to:\s*([^\n]+)/i,
      /Are you:\s*([^\n]+)/i,
      /Do you want to:\s*([^\n]+)/i
    ];

    for (const pattern of patterns) {
      const match = content.match(pattern);
      if (match) {
        const options = match[1]
          .split(/[|,]/)
          .map(s => s.trim())
          .filter(s => s.length > 0);

        if (options.length > 0) {
          const cleanContent = content.replace(match[0], '').trim();
          return { text: cleanContent, options };
        }
      }
    }

    // Check for yes/no questions
    const yesNoPattern = /\b(?:yes|no)\b.*?\b(?:yes|no)\b/i;
    const yesNoMatch = content.match(yesNoPattern);
    if (yesNoMatch) {
      return { text: content, options: ['Yes', 'No'] };
    }

    return { text: content };
  }

  private updateContext(content: string) {
    // Extract state information
    const stateMatch = content.match(/(?:in|for|from)\s+([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)/);
    if (stateMatch) {
      this.context.state = stateMatch[1];
    }

    // Extract age information
    const ageMatch = content.match(/\b(\d+)\s*(?:years?\s*old|years?\s*of\s*age)\b/i);
    if (ageMatch) {
      this.context.age = parseInt(ageMatch[1], 10);
    }

    // Extract license type information
    const licenseTypes = [
      'standard', 'REAL ID', 'commercial', 'CDL', 'learner\'s permit',
      'motorcycle', 'enhanced', 'provisional', 'graduated', 'restricted'
    ];

    for (const type of licenseTypes) {
      if (content.toLowerCase().includes(type.toLowerCase())) {
        this.context.licenseType = type;
        break;
      }
    }

    // Extract current process/step
    const stepPatterns = [
      /(?:currently|now|step|process):\s*([^.!?]+)[.!?]/i,
      /(?:you are|you're|user is) (?:in|at) (?:the|your) ([^.!?]+) (?:step|stage|process)/i,
      /(?:next step|next phase) (?:is|will be) ([^.!?]+)[.!?]/i
    ];

    for (const pattern of stepPatterns) {
      const match = content.match(pattern);
      if (match) {
        this.context.currentStep = match[1].trim();
        break;
      }
    }

    // Extract requirements
    const requirementPatterns = [
      /(?:need|require|must have|should bring|need to|required)\s+([^.!?]+)[.!?]/i,
      /requirements?\s+(?:include|are|is)\s+([^.!?]+)[.!?]/i,
      /bring\s+(?:the\s+)?following\s+([^.!?]+)[.!?]/i
    ];

    const requirements = new Set<string>();
    for (const pattern of requirementPatterns) {
      const matches = content.matchAll(new RegExp(pattern, 'gi'));
      for (const match of matches) {
        if (match[1]) {
          const req = match[1].trim()
            .replace(/^\s*[-•]\s*/, '')  // Remove bullet points
            .replace(/\*\*/g, '');       // Remove markdown bold
          requirements.add(req);
        }
      }
    }

    if (requirements.size > 0) {
      this.context.requirements = Array.from(requirements);
    }
  }

  public update(chunk: string): ParsedResponse {
    this.buffer = chunk;
    
    const hasEndMarker = /[.!?](?:\s|$)/.test(chunk);
    this.isComplete = hasEndMarker;
    
    if (this.buffer === this.lastParsedContent) {
      const { text, options } = this.extractOptions(this.buffer);
      return { 
        text,
        options,
        isComplete: this.isComplete
      };
    }
    
    this.lastParsedContent = this.buffer;
    this.updateContext(this.buffer);
    const { text, options } = this.extractOptions(this.buffer);
    
    return {
      text,
      options,
      isComplete: this.isComplete
    };
  }

  public addMessage(message: Message) {
    if (message.isComplete) {
      this.context.messages.push(message);
      
      if (this.context.messages.length > 10) {
        this.context.messages = this.context.messages.slice(-10);
      }

      this.updateContext(message.content);

      if (message.role === 'user') {
        this.context.previousTopics.push(message.content);
        if (this.context.previousTopics.length > 5) {
          this.context.previousTopics = this.context.previousTopics.slice(-5);
        }
      }
    }
  }

  public getContext(): ChatContext {
    return this.context;
  }

  public reset() {
    this.buffer = '';
    this.lastParsedContent = '';
    this.isComplete = false;
    this.context = {
      previousTopics: [],
      messages: [],
    };
  }
}