import React from 'react';
import { motion } from 'framer-motion';
import { Hero } from './Hero';
import { Features } from './Features';
import { ServiceTabs } from './ServiceTabs';
import { DemoSection } from './DemoSection';
import { PainPoints } from './PainPoints';
import { FeaturesShowcase } from './FeaturesShowcase';
import { FAQ } from './FAQ';
import { TrustBadges } from './TrustBadges';
import { Footer } from './Footer';

interface WelcomeOverlayProps {
  isVisible: boolean;
  onDismiss: () => void;
}

export function WelcomeOverlay({ isVisible, onDismiss }: WelcomeOverlayProps) {
  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 bg-white overflow-y-auto overflow-x-hidden welcome-overlay overscroll-none"
    >
      <div className="min-h-screen overflow-x-hidden">
        <Hero onDismiss={onDismiss} />
        <Features />
        <ServiceTabs />
        <DemoSection onDismiss={onDismiss} />
        <PainPoints />
        <FeaturesShowcase />
        <FAQ />
        <TrustBadges onDismiss={onDismiss} />
        <Footer />
      </div>
    </motion.div>
  );
}