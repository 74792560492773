import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FileText, Car, Key } from 'lucide-react';

interface Service {
  id: string;
  label: string;
  icon: React.ElementType;
  image: string;
  description: string;
  features: string[];
}

export function ServiceTabs() {
  const [activeTab, setActiveTab] = useState('licenses');

  const services: Service[] = [
    {
      id: 'licenses',
      label: "Driver's License",
      icon: FileText,
      image: "https://driving-tests.org/premium/img/busy.jpg",
      description: "Get step-by-step guidance for obtaining, renewing, or replacing your driver's license.",
      features: ['New applications', 'Renewals', 'REAL ID', 'Commercial CDL']
    },
    {
      id: 'vehicles',
      label: 'Vehicle Services',
      icon: Car,
      image: "https://driving-tests.org/wp-content/uploads/autotest/tailgating-1.jpg",
      description: "Handle all your vehicle-related needs with our comprehensive registration services.",
      features: ['Registration', 'Title transfer', 'Plates', 'Insurance']
    },
    {
      id: 'permits',
      label: 'Permits & IDs',
      icon: Key,
      image: "https://driving-tests.org/premium/teen2.jpg",
      description: "Learn about different types of permits and identification cards available in your state.",
      features: ["Learner's permit", 'Temporary license', 'Work permits']
    }
  ];

  const activeService = services.find(s => s.id === activeTab);

  return (
    <div className="max-w-[1080px] mx-auto px-4 md:px-8 py-16 md:py-24">
      <div className="text-center mb-8 md:mb-16">
        <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 mb-3 md:mb-4">
          Everything you need in one place
        </h2>
        <p className="text-base md:text-xl text-gray-600">
          From license applications to vehicle registration, we've got you covered
        </p>
      </div>

      {/* Service Tabs */}
      <div className="flex justify-center mb-8 md:mb-12">
        <div className="w-full max-w-md md:max-w-none inline-flex bg-gray-100 rounded-full p-1">
          {services.map((service) => (
            <motion.button
              key={service.id}
              onClick={() => setActiveTab(service.id)}
              className={`relative flex-1 px-3 md:px-6 py-2 md:py-3 rounded-full text-xs md:text-sm font-medium transition-colors ${
                activeTab === service.id
                  ? 'text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              {activeTab === service.id && (
                <motion.div
                  layoutId="activeTab"
                  className="absolute inset-0 bg-blue-600 rounded-full"
                  transition={{ type: "spring", duration: 0.5 }}
                />
              )}
              <span className="relative z-10 flex items-center justify-center gap-1.5 md:gap-2">
                <service.icon size={14} className="hidden md:block" />
                <span className="truncate">{service.label}</span>
              </span>
            </motion.button>
          ))}
        </div>
      </div>

      {/* Service Content */}
      {activeService && (
        <div className="grid md:grid-cols-2 gap-6 md:gap-12 items-center">
          {/* Image */}
          <motion.div
            key={activeService.id + "-image"}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            className="relative rounded-2xl md:rounded-3xl overflow-hidden aspect-[4/3]"
          >
            <img
              src={activeService.image}
              alt={activeService.label}
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
          </motion.div>

          {/* Content */}
          <motion.div
            key={activeService.id + "-content"}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-4 md:space-y-6"
          >
            <div className="inline-flex p-2 md:p-3 rounded-xl md:rounded-2xl bg-blue-50 text-blue-600">
              <activeService.icon size={20} className="md:size-6" />
            </div>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-900">
              {activeService.label}
            </h3>
            <p className="text-sm md:text-base text-gray-600">
              {activeService.description}
            </p>
            <ul className="space-y-2 md:space-y-3">
              {activeService.features.map((feature) => (
                <li key={feature} className="flex items-center gap-2 text-sm md:text-base text-gray-600">
                  <div className="w-1.5 h-1.5 rounded-full bg-blue-600" />
                  {feature}
                </li>
              ))}
            </ul>
          </motion.div>
        </div>
      )}
    </div>
  );
}