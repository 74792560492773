import React, { useState } from 'react';
import { ChecklistHeader } from './ChecklistHeader';
import { ChecklistContent } from './ChecklistContent';
import type { ChecklistItem } from '@/types';

interface ChecklistProps {
  items: ChecklistItem[];
  onToggle: (id: string) => void;
}

export function Checklist({ items, onToggle }: ChecklistProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handlePrint = () => {
    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>DMV Checklist</title>
          <style>
            body {
              font-family: system-ui, -apple-system, sans-serif;
              padding: 2rem;
              max-width: 800px;
              margin: 0 auto;
              line-height: 1.5;
            }
            .checklist-item {
              margin-bottom: 1rem;
              padding: 1rem;
              border: 1px solid #e5e7eb;
              border-radius: 0.5rem;
              page-break-inside: avoid;
            }
            .checklist-title {
              font-size: 1.25rem;
              font-weight: 600;
              margin-bottom: 1.5rem;
              color: #1f2937;
            }
            .item-header {
              display: flex;
              gap: 1rem;
              align-items: flex-start;
            }
            .checkbox {
              width: 1.25rem;
              height: 1.25rem;
              border: 2px solid #d1d5db;
              border-radius: 0.25rem;
              flex-shrink: 0;
              margin-top: 0.125rem;
            }
            .checked {
              background-color: #2563eb;
              border-color: #2563eb;
              position: relative;
            }
            .checked::after {
              content: '✓';
              color: white;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 0.875rem;
            }
            .item-text {
              font-weight: 500;
              color: #1f2937;
              flex: 1;
            }
            @media print {
              body {
                padding: 1rem;
              }
              .no-print {
                display: none;
              }
              @page {
                margin: 0.5in;
              }
            }
          </style>
        </head>
        <body>
          <div class="checklist-title">DMV Checklist</div>
          ${items.map((item, index) => `
            <div class="checklist-item">
              <div class="item-header">
                <div class="checkbox ${item.isCompleted ? 'checked' : ''}"></div>
                <div class="item-text">${index + 1}. ${item.text}</div>
              </div>
              ${item.details ? `
                <div class="item-details">
                  ${item.details}
                </div>
              ` : ''}
            </div>
          `).join('')}
        </body>
      </html>
    `;

    const blob = new Blob([printContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url);
    
    if (printWindow) {
      printWindow.onload = () => {
        URL.revokeObjectURL(url);
        printWindow.print();
      };
    }
  };

  return (
    <div className="space-y-2">
      <ChecklistHeader
        isExpanded={isExpanded}
        onToggle={() => setIsExpanded(!isExpanded)}
        onPrint={handlePrint}
      />
      <ChecklistContent
        items={items}
        isExpanded={isExpanded}
        onToggle={onToggle}
      />
    </div>
  );
}