import React from 'react';
import { Download, Trash2 } from 'lucide-react';
import { format } from 'date-fns';

interface FeedbackControlsProps {
  timeRange: string;
  selectedDate: Date | null;
  onTimeRangeChange: (range: string) => void;
  onDateChange: (date: Date | null) => void;
  onExport: () => void;
  onClearAll: () => void;
}

export function FeedbackControls({
  timeRange,
  selectedDate,
  onTimeRangeChange,
  onDateChange,
  onExport,
  onClearAll,
}: FeedbackControlsProps) {
  return (
    <div className="flex flex-wrap gap-4 items-center justify-between bg-white dark:bg-gray-800 rounded-lg shadow p-4">
      <div className="flex flex-wrap gap-4 items-center">
        <select
          value={timeRange}
          onChange={(e) => onTimeRangeChange(e.target.value)}
          className="px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
        >
          <option value="all">All Time</option>
          <option value="today">Today</option>
          <option value="7days">Last 7 Days</option>
          <option value="30days">Last 30 Days</option>
        </select>

        <input
          type="date"
          value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
          onChange={(e) => onDateChange(e.target.value ? new Date(e.target.value) : null)}
          className="px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
        />

        <button
          onClick={() => {
            onTimeRangeChange('all');
            onDateChange(null);
          }}
          className="px-3 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
        >
          Clear Filters
        </button>
      </div>

      <div className="flex gap-4">
        <button
          onClick={onExport}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Download size={16} />
          Export CSV
        </button>

        <button
          onClick={onClearAll}
          className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          <Trash2 size={16} />
          Clear All
        </button>
      </div>
    </div>
  );
}