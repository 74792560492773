import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Car, Key, HelpCircle, ClipboardCheck, Calendar, CreditCard, MapPin } from 'lucide-react';

interface QuickActionsProps {
  onSelect: (action: string) => void;
}

export function QuickActions({ onSelect }: QuickActionsProps) {
  const actions = [
    { 
      icon: FileText, 
      text: "Driver's License", 
      subtext: "New, renewal, or replacement",
      value: "I need help with getting a driver's license" 
    },
    { 
      icon: Car, 
      text: "Vehicle Registration", 
      subtext: "Register or renew",
      value: "I need help with vehicle registration" 
    },
    { 
      icon: Key, 
      text: "Permits", 
      subtext: "Learner's or temporary",
      value: "I need information about permits" 
    },
    { 
      icon: ClipboardCheck, 
      text: "REAL ID", 
      subtext: "Requirements & process",
      value: "Tell me about REAL ID requirements" 
    },
    { 
      icon: Calendar, 
      text: "Appointments", 
      subtext: "Schedule or manage",
      value: "How do I schedule a DMV appointment?" 
    },
    { 
      icon: CreditCard, 
      text: "Fees & Payment", 
      subtext: "Cost information",
      value: "What are the fees for DMV services?" 
    },
    { 
      icon: MapPin, 
      text: "Locations", 
      subtext: "Find nearby offices",
      value: "Help me find a DMV office" 
    },
    { 
      icon: HelpCircle, 
      text: "Other Services", 
      subtext: "Additional assistance",
      value: "What other services do you provide?" 
    },
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-3 mt-4 mb-2"
    >
      {actions.map(({ icon: Icon, text, subtext, value }) => (
        <motion.button
          key={text}
          variants={item}
          whileHover={{ scale: 1.02, y: -2 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => onSelect(value)}
          className="flex flex-col items-center gap-1.5 md:gap-2 p-2.5 md:p-3 rounded-xl bg-white dark:bg-gray-900 border border-gray-200/50 dark:border-gray-800/50 text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 hover:border-gray-300 dark:hover:border-gray-700 transition-all duration-200 shadow-sm hover:shadow-md group"
        >
          <div className="relative">
            <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-500/10 rounded-lg blur-lg group-hover:blur-xl transition-all duration-200" />
            <div className="relative p-1.5 rounded-lg bg-gradient-to-br from-blue-500/10 to-blue-600/10 dark:from-blue-400/10 dark:to-blue-500/10">
              <Icon size={16} className="md:size-4 text-blue-600 dark:text-blue-400" />
            </div>
          </div>
          <div className="text-center">
            <div className="text-xs md:text-sm font-medium line-clamp-1">{text}</div>
            <div className="text-[10px] md:text-xs text-gray-500 dark:text-gray-400 line-clamp-1">{subtext}</div>
          </div>
        </motion.button>
      ))}
    </motion.div>
  );
}