import { supabase, isSupabaseConfigured } from '@/lib/supabase';
import type { Feedback } from '@/types/feedback';

function getLocalFeedback(): Feedback[] {
  try {
    return JSON.parse(localStorage.getItem('dmv_feedback') || '[]');
  } catch {
    return [];
  }
}

function saveLocalFeedback(feedback: Feedback[]) {
  try {
    localStorage.setItem('dmv_feedback', JSON.stringify(feedback));
  } catch (error) {
    console.error('Error saving to localStorage:', error);
  }
}

export const FeedbackService = {
  async submitFeedback(feedback: Omit<Feedback, 'id' | 'created_at' | 'last_modified'>) {
    if (!isSupabaseConfigured()) {
      return handleLocalFeedback(feedback);
    }

    try {
      // First try to find existing feedback
      const { data: existingData, error: selectError } = await supabase
        .from('feedback')
        .select('id')
        .eq('message_id', feedback.message_id)
        .maybeSingle();

      if (selectError) throw selectError;

      if (existingData?.id) {
        // Update existing feedback
        const { data: updatedData, error: updateError } = await supabase
          .from('feedback')
          .update({
            content: feedback.content,
            is_positive: feedback.is_positive,
            comment: feedback.comment || null, // Ensure null for empty comments
            timestamp: feedback.timestamp,
            url: feedback.url
          })
          .eq('id', existingData.id)
          .select()
          .single();

        if (updateError) throw updateError;
        return updatedData;
      }

      // Insert new feedback
      const { data: insertedData, error: insertError } = await supabase
        .from('feedback')
        .insert([{
          ...feedback,
          comment: feedback.comment || null // Ensure null for empty comments
        }])
        .select()
        .single();

      if (insertError) throw insertError;
      return insertedData;
    } catch (error) {
      console.error('Error submitting feedback:', error);
      // Fall back to local storage if Supabase operation fails
      return handleLocalFeedback(feedback);
    }
  },

  async getFeedback(options: { timeRange?: string; date?: string } = {}) {
    if (!isSupabaseConfigured()) {
      return getLocalFeedback();
    }

    try {
      let query = supabase
        .from('feedback')
        .select('*')
        .order('created_at', { ascending: false });

      if (options.timeRange) {
        const now = new Date();
        let startDate = new Date();

        switch (options.timeRange) {
          case 'today':
            startDate.setHours(0, 0, 0, 0);
            break;
          case '7days':
            startDate.setDate(now.getDate() - 7);
            break;
          case '30days':
            startDate.setDate(now.getDate() - 30);
            break;
        }

        if (options.timeRange !== 'all') {
          query = query.gte('created_at', startDate.toISOString());
        }
      }

      if (options.date) {
        const startDate = new Date(options.date);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);

        query = query
          .gte('created_at', startDate.toISOString())
          .lt('created_at', endDate.toISOString());
      }

      const { data, error } = await query;
      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error('Error fetching feedback:', error);
      return getLocalFeedback();
    }
  },

  async clearAllFeedback() {
    if (!isSupabaseConfigured()) {
      localStorage.removeItem('dmv_feedback');
      return;
    }

    try {
      const { error } = await supabase
        .from('feedback')
        .delete()
        .neq('id', '00000000-0000-0000-0000-000000000000');

      if (error) throw error;
    } catch (error) {
      console.error('Error clearing feedback:', error);
      localStorage.removeItem('dmv_feedback');
    }
  }
};

function handleLocalFeedback(feedback: Omit<Feedback, 'id' | 'created_at' | 'last_modified'>) {
  const existingFeedback = getLocalFeedback();
  const existingIndex = existingFeedback.findIndex(f => f.message_id === feedback.message_id);
  const now = new Date().toISOString();
  
  const newFeedback = {
    ...feedback,
    id: crypto.randomUUID(),
    comment: feedback.comment || null,
    created_at: now,
    last_modified: now
  };
  
  if (existingIndex >= 0) {
    existingFeedback[existingIndex] = {
      ...existingFeedback[existingIndex],
      ...newFeedback,
      created_at: existingFeedback[existingIndex].created_at,
      last_modified: now
    };
  } else {
    existingFeedback.push(newFeedback);
  }
  
  saveLocalFeedback(existingFeedback);
  return newFeedback;
}