import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThumbsUp, ThumbsDown, X, Send, MessageSquare, Loader2 } from 'lucide-react';
import { FeedbackService } from '@/services/feedbackService';

interface FeedbackButtonsProps {
  messageId: string;
  content: string;
}

export function FeedbackButtons({ messageId, content }: FeedbackButtonsProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [comment, setComment] = useState('');
  const [showCommentField, setShowCommentField] = useState(false);
  const [lastVote, setLastVote] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleVote = async (isPositive: boolean) => {
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      await FeedbackService.submitFeedback({
        message_id: messageId,
        content,
        is_positive: isPositive,
        comment: '',
        timestamp: new Date().toISOString(),
        url: window.location.href,
      });

      setHasVoted(true);
      setLastVote(isPositive);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCommentSubmit = async () => {
    if (isSubmitting || !comment.trim() || lastVote === null) return;
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      await FeedbackService.submitFeedback({
        message_id: messageId,
        content,
        is_positive: lastVote,
        comment: comment.trim(),
        timestamp: new Date().toISOString(),
        url: window.location.href,
      });

      setComment('');
      setShowCommentField(false);
    } catch (error) {
      console.error('Error submitting comment:', error);
      setError('Failed to submit comment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (hasVoted && !showCommentField) {
    return (
      <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
        <span>Thanks for your feedback!</span>
        <button
          onClick={() => setShowCommentField(true)}
          className="flex items-center gap-1 text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
        >
          <MessageSquare size={14} />
          <span>Add a comment</span>
        </button>
      </div>
    );
  }

  return (
    <div className="relative">
      <motion.div
        initial={false}
        animate={{ height: showCommentField ? 'auto' : 32 }}
        className="overflow-hidden"
      >
        <div className="flex items-center gap-2">
          {!hasVoted ? (
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Was this response helpful?
              </span>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleVote(true)}
                disabled={isSubmitting}
                className="p-1.5 rounded-lg text-green-600 dark:text-green-400 hover:bg-green-100 dark:hover:bg-green-900/30"
              >
                <ThumbsUp size={18} />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleVote(false)}
                disabled={isSubmitting}
                className="p-1.5 rounded-lg text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-900/30"
              >
                <ThumbsDown size={18} />
              </motion.button>
            </div>
          ) : showCommentField ? (
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="w-full"
              >
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Share your thoughts... (optional)"
                      className="flex-1 px-3 py-1.5 text-sm rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500/10 dark:focus:ring-blue-400/10"
                    />
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleCommentSubmit}
                      disabled={isSubmitting || !comment.trim()}
                      className="px-3 py-1.5 rounded-lg bg-blue-600 dark:bg-blue-500 text-white text-sm font-medium hover:bg-blue-700 dark:hover:bg-blue-600 disabled:opacity-50"
                    >
                      {isSubmitting ? (
                        <Loader2 size={14} className="animate-spin" />
                      ) : (
                        <Send size={14} />
                      )}
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        setShowCommentField(false);
                        setComment('');
                      }}
                      className="p-1.5 rounded-lg text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    >
                      <X size={14} />
                    </motion.button>
                  </div>
                  {error && (
                    <p className="text-sm text-red-500 dark:text-red-400">
                      {error}
                    </p>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          ) : null}
        </div>
      </motion.div>
    </div>
  );
}