import React from 'react';
import { motion } from 'framer-motion';
import { Zap, ListChecks, Clock, UserCheck } from 'lucide-react';
import { StatisticsCarousel } from './StatisticsCarousel';

export function Features() {
  const features = [
    {
      icon: Zap,
      title: 'Instant Answers',
      description: 'Access up-to-date information without the wait.'
    },
    {
      icon: ListChecks,
      title: 'Simplified Processes',
      description: 'Clear, step-by-step guidance for all requirements.'
    },
    {
      icon: Clock,
      title: '24/7 Availability',
      description: 'Get assistance anytime, anywhere.'
    },
    {
      icon: UserCheck,
      title: 'Personalized Support',
      description: 'Receive information relevant to your specific situation.'
    }
  ];

  return (
    <div className="max-w-[1080px] mx-auto px-4 md:px-8 py-16 md:py-24">
      {/* Statistics Carousel */}
      <StatisticsCarousel />

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-12">
        {features.map((feature, index) => (
          <motion.div
            key={feature.title}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            viewport={{ once: true }}
            className="text-center group"
          >
            <div className="relative inline-block mb-4 md:mb-6">
              <motion.div
                className="absolute inset-0 bg-blue-500/20 rounded-2xl md:rounded-3xl blur-xl"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.3, 0.5, 0.3],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                  delay: index * 0.2,
                }}
              />
              <div className="relative p-3 md:p-4 rounded-xl md:rounded-2xl bg-gradient-to-br from-blue-500/10 to-blue-600/10 dark:from-blue-400/10 dark:to-blue-500/10 group-hover:from-blue-500/20 group-hover:to-blue-600/20 transition-all duration-300">
                <feature.icon size={20} className="md:size-6 text-blue-600 dark:text-blue-400" />
              </div>
            </div>
            <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-2 md:mb-3">
              {feature.title}
            </h3>
            <p className="text-sm md:text-base text-gray-600 leading-relaxed">
              {feature.description}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}