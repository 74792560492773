import React from 'react';

export function ChecklistSkeleton() {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-center py-2">
        <div className="flex items-center gap-3">
          <div className="w-2 h-2 bg-blue-500/50 dark:bg-blue-400/50 rounded-full animate-pulse" />
          <div className="text-sm text-gray-500 dark:text-gray-400 font-medium">
            Creating your checklist...
          </div>
          <div className="w-2 h-2 bg-blue-500/50 dark:bg-blue-400/50 rounded-full animate-pulse [animation-delay:0.2s]" />
        </div>
      </div>
      {[1, 2, 3].map((i) => (
        <div
          key={i}
          className="rounded-xl border border-gray-200/50 dark:border-gray-800/50 bg-white/50 dark:bg-gray-900/50 overflow-hidden shadow-sm"
        >
          <div className="p-3">
            <div className="flex items-start gap-3">
              <div className="h-5 w-5 rounded-lg bg-gray-200/50 dark:bg-gray-700/50 animate-pulse" />
              <div className="flex-1 space-y-3">
                <div className="h-4 bg-gray-200/50 dark:bg-gray-700/50 rounded-lg w-3/4 animate-pulse" />
                <div className="h-4 bg-gray-200/50 dark:bg-gray-700/50 rounded-lg w-1/2 animate-pulse [animation-delay:0.1s]" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}