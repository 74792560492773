import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Mic, Globe, Car, Shield, Bot, Clock, CheckCircle } from 'lucide-react';

export function FeaturesShowcase() {
  return (
    <div className="max-w-[1080px] mx-auto px-4 md:px-8 py-16 md:py-24">
      <div className="text-center mb-12 md:mb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <span className="inline-block px-4 py-2 bg-blue-50 text-blue-600 rounded-full text-sm font-medium mb-4">
            AI-Powered Features
          </span>
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 mb-3 md:mb-4">
            Experience the future of DMV services
          </h2>
          <p className="text-base md:text-xl text-gray-600">
            Powered by advanced AI to make your DMV experience seamless
          </p>
        </motion.div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 auto-rows-[140px] md:auto-rows-[180px]">
        {/* Main Feature */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="col-span-2 row-span-2 p-4 md:p-8 bg-gradient-to-br from-blue-50 to-white rounded-3xl border border-blue-100/50 hover:shadow-lg transition-shadow relative overflow-hidden group"
        >
          <div className="relative z-10">
            <div className="flex items-center gap-2 mb-4 md:mb-6">
              <Bot size={20} className="md:size-6 text-blue-600" />
              <span className="text-blue-900 font-medium">AI Assistant</span>
            </div>
            <h3 className="text-xl md:text-3xl font-semibold mb-2 md:mb-4 text-gray-900">
              24/7 Expert
              <br />
              Guidance
            </h3>
            <p className="text-sm md:text-base text-gray-600 mb-4 md:mb-6">
              Get instant, accurate answers to all your DMV-related questions
            </p>
            <div className="flex gap-2 md:gap-4">
              <div className="p-1.5 md:p-2 rounded-lg bg-white/80 text-blue-600">
                <MessageSquare size={16} className="md:size-5" />
              </div>
              <div className="p-1.5 md:p-2 rounded-lg bg-white/80 text-blue-600">
                <Mic size={16} className="md:size-5" />
              </div>
              <div className="p-1.5 md:p-2 rounded-lg bg-white/80 text-blue-600">
                <Globe size={16} className="md:size-5" />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 w-24 md:w-32 h-24 md:h-32 bg-blue-500/10 rounded-full blur-2xl group-hover:scale-150 transition-transform duration-500" />
        </motion.div>

        {/* Voice Interaction */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="p-4 md:p-6 bg-gradient-to-br from-purple-50 to-white rounded-3xl border border-purple-100/50 hover:shadow-lg transition-shadow"
        >
          <div className="inline-flex p-2 md:p-3 rounded-2xl bg-purple-100/50 text-purple-600 mb-3 md:mb-4">
            <Mic size={16} className="md:size-5" />
          </div>
          <h3 className="text-base md:text-lg font-semibold text-gray-900 mb-1 md:mb-2">
            Voice Chat
          </h3>
          <p className="text-xs md:text-sm text-gray-600">
            Natural conversations
          </p>
        </motion.div>

        {/* Real-time Processing */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="p-4 md:p-6 bg-gradient-to-br from-green-50 to-white rounded-3xl border border-green-100/50 hover:shadow-lg transition-shadow"
        >
          <div className="inline-flex p-2 md:p-3 rounded-2xl bg-green-100/50 text-green-600 mb-3 md:mb-4">
            <Clock size={16} className="md:size-5" />
          </div>
          <h3 className="text-base md:text-lg font-semibold text-gray-900 mb-1 md:mb-2">
            Instant Results
          </h3>
          <p className="text-xs md:text-sm text-gray-600">
            Quick answers
          </p>
        </motion.div>

        {/* Multi-state Support */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="col-span-2 p-4 md:p-6 bg-gradient-to-br from-orange-50 to-white rounded-3xl border border-orange-100/50 hover:shadow-lg transition-shadow"
        >
          <div className="inline-flex p-2 md:p-3 rounded-2xl bg-orange-100/50 text-orange-600 mb-3 md:mb-4">
            <Globe size={16} className="md:size-5" />
          </div>
          <h3 className="text-base md:text-lg font-semibold text-gray-900 mb-1 md:mb-2">
            50 States Coverage
          </h3>
          <p className="text-xs md:text-sm text-gray-600">
            State-specific guidance
          </p>
        </motion.div>

        {/* Vehicle Services */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="p-4 md:p-6 bg-gradient-to-br from-rose-50 to-white rounded-3xl border border-rose-100/50 hover:shadow-lg transition-shadow"
        >
          <div className="inline-flex p-2 md:p-3 rounded-2xl bg-rose-100/50 text-rose-600 mb-3 md:mb-4">
            <Car size={16} className="md:size-5" />
          </div>
          <h3 className="text-base md:text-lg font-semibold text-gray-900 mb-1 md:mb-2">
            Vehicle Services
          </h3>
          <p className="text-xs md:text-sm text-gray-600">
            Registration & titles
          </p>
        </motion.div>

        {/* Compliance */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="p-4 md:p-6 bg-gradient-to-br from-teal-50 to-white rounded-3xl border border-teal-100/50 hover:shadow-lg transition-shadow"
        >
          <div className="inline-flex p-2 md:p-3 rounded-2xl bg-teal-100/50 text-teal-600 mb-3 md:mb-4">
            <CheckCircle size={16} className="md:size-5" />
          </div>
          <h3 className="text-base md:text-lg font-semibold text-gray-900 mb-1 md:mb-2">
            Always Updated
          </h3>
          <p className="text-xs md:text-sm text-gray-600">
            Latest requirements
          </p>
        </motion.div>
      </div>
    </div>
  );
}