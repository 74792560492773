import React from 'react';
import { ChevronDown, Printer } from 'lucide-react';

interface ChecklistHeaderProps {
  isExpanded: boolean;
  onToggle: () => void;
  onPrint: () => void;
}

export function ChecklistHeader({ isExpanded, onToggle, onPrint }: ChecklistHeaderProps) {
  return (
    <div className="flex items-center gap-2">
      <button
        onClick={onToggle}
        className="inline-flex items-center gap-2 px-3 py-1.5 text-sm bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-lg hover:bg-blue-100 dark:hover:bg-blue-900/50 transition-colors"
      >
        <ChevronDown
          size={16}
          className={`transform transition-transform duration-200 ${
            isExpanded ? 'rotate-180' : ''
          }`}
        />
        View Checklist
      </button>
      <button
        onClick={onPrint}
        className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors"
      >
        <Printer size={16} />
        <span>Print</span>
      </button>
    </div>
  );
}