import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChatApp } from './pages/ChatApp';
import { AdminDashboard } from './pages/AdminDashboard';
import { DiagramTest } from './components/DiagramTest';

export function App() {
  return (
    <Routes>
      <Route path="/" element={<ChatApp />} />
      <Route path="/admin" element={<AdminDashboard />} />
      <Route path="/test-diagram" element={<DiagramTest />} />
    </Routes>
  );
}