import React from 'react';
import { Bot } from 'lucide-react';

export function MessageSkeleton() {
  return (
    <div className="flex gap-4 p-4 rounded-2xl bg-white/50 dark:bg-gray-800/30 shadow-sm">
      <div className="flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-xl bg-gradient-to-br from-blue-500/10 to-blue-600/10 dark:from-blue-400/10 dark:to-blue-500/10 text-blue-600 dark:text-blue-400">
        <Bot size={18} />
      </div>
      <div className="flex-1 space-y-3">
        <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
          DMV Assistant
        </div>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200/50 dark:bg-gray-700/50 rounded-lg w-3/4 animate-pulse" />
          <div className="h-4 bg-gray-200/50 dark:bg-gray-700/50 rounded-lg w-1/2 animate-pulse [animation-delay:0.1s]" />
          <div className="h-4 bg-gray-200/50 dark:bg-gray-700/50 rounded-lg w-2/3 animate-pulse [animation-delay:0.2s]" />
        </div>
      </div>
    </div>
  );
}