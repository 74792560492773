import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Bot, User, Volume2, Pause, Loader2, GitBranch, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSkeleton } from './MessageSkeleton';
import { Checklist } from './Checklist';
import { ChecklistSkeleton } from './ChecklistSkeleton';
import { SuggestionBubbles } from './SuggestionBubbles';
import { StateSelector } from './StateSelector';
import { AgePicker } from './AgePicker';
import { FeedbackButtons } from './FeedbackButtons';
import { DiagramViewer } from './DiagramViewer';
import { extractStepsFromText, formatDiagramContent } from '../utils/diagramUtils';
import { synthesizeSpeech } from '../utils/openai';
import type { Message, ChecklistItem } from '../types';

interface ChatMessageProps {
  message: Message;
  onSuggestionSelect?: (suggestion: string) => void;
  autoPlayEnabled?: boolean;
  isInitialMessage?: boolean;
}

function formatMessageContent(content: string): React.ReactNode {
  // Remove the "Here's a visual representation" line and everything after it
  const parts = content.split(/Here's a visual representation/);
  const mainContent = parts[0];
  const paragraphs = mainContent.split(/\n+/).filter(Boolean);
  
  // URL regex that matches markdown links [text](url) and plain URLs
  const urlRegex = /\[([^\]]+)\]\(([^)]+)\)|https?:\/\/[^\s)]+/g;
  
  return paragraphs.map((paragraph, pIndex) => {
    if (/^\d+\.\s/.test(paragraph)) {
      // Handle numbered lists
      const formattedText = paragraph
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(urlRegex, (match, text, url) => {
          if (text && url) {
            // Markdown link format
            return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 underline">${text}</a>`;
          }
          // Plain URL
          return `<a href="${match}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 underline">${match}</a>`;
        });

      return (
        <React.Fragment key={pIndex}>
          {pIndex > 0 && <br />}
          <div 
            className="pl-6 -indent-6"
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </React.Fragment>
      );
    }

    const formattedText = paragraph
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(urlRegex, (match, text, url) => {
        if (text && url) {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 underline">${text}</a>`;
        }
        return `<a href="${match}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 underline">${match}</a>`;
      });

    return (
      <React.Fragment key={pIndex}>
        {pIndex > 0 && <br />}
        <div dangerouslySetInnerHTML={{ __html: formattedText }} />
      </React.Fragment>
    );
  });
}

const audioController = {
  currentAudio: null as HTMLAudioElement | null,
  currentUrl: null as string | null,
  currentMessageId: null as string | null,
  isProcessing: false,
  
  stop() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio.onended = null;
      this.currentAudio.onerror = null;
      this.currentAudio = null;
    }
    if (this.currentUrl) {
      URL.revokeObjectURL(this.currentUrl);
      this.currentUrl = null;
    }
    this.currentMessageId = null;
    this.isProcessing = false;
  }
};

export function ChatMessage({ message, onSuggestionSelect, autoPlayEnabled, isInitialMessage = false }: ChatMessageProps) {
  const isAssistant = message.role === 'assistant';
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const diagramRef = useRef<string | null>(null);
  const retryCountRef = useRef<number>(0);
  const [isChecklistExpanded, setIsChecklistExpanded] = useState(false);
  const [checklist, setChecklist] = useState<ChecklistItem[]>([]);
  const [isLoadingChecklist, setIsLoadingChecklist] = useState(false);
  const maxRetries = 3;
  const autoPlayAttemptedRef = useRef(false);
  const messageContentRef = useRef(message.content);
  const audioReadyRef = useRef(false);
  const initialRenderRef = useRef(true);
  const suggestions = message.suggestions;

  const isStateRequest = message.content.toLowerCase().includes('which state');
  const isAgeRequest = useCallback((content: string) => {
    return /what'?s your age|how old are you|could you .* know your age|let me know your age|tell me your age|may i know your age|what is your age/i.test(content);
  }, []);

  useEffect(() => {
    if (message.content) {
      const steps = extractStepsFromText(message.content);
      console.log('Steps found:', steps.length, 'Initial message:', isInitialMessage);
      
      if (steps.length >= 2) {
        diagramRef.current = formatDiagramContent(steps);
        console.log('Diagram content generated:', diagramRef.current);
      } else {
        diagramRef.current = null;
      }
    }
  }, [message.content]);

  useEffect(() => {
    // Reset diagram state when message changes
    setShowDiagram(false);
  }, [message.id]);

  useEffect(() => {
    if (message.isComplete && diagramRef.current) {
      console.log('Message complete, diagram ready');
    }
  }, [message.isComplete]);

  useEffect(() => {
    if (message.isComplete && message.role === 'assistant') {
      // Extract checklist items from numbered lists
      try {
        const lines = message.content.split('\n');
        const items = lines
          .filter(line => /^\d+\.\s/.test(line))
          .map((line, index) => {
            // Extract the main text and any details after a colon
            let text = line
              .replace(/^\d+\.\s+/, '') // Remove the number prefix
              .replace(/:\s*$/, ''); // Remove trailing colon if present
            
            // Clean up the text by removing markdown and extra whitespace
            const cleanText = text
              .replace(/\*\*/g, '')
              .replace(/^\s*[-•]\s*/, '')
              .trim();
            
            return {
              id: `${message.id}-${index}`,
              text: cleanText,
              isCompleted: false
            };
          });

        if (items.length >= 2) {
          setChecklist(items);
          setIsLoadingChecklist(false);
        }
      } catch (error) {
        console.error('Error parsing checklist items:', error);
        setChecklist([]);
        setIsLoadingChecklist(false);
      }
    }
  }, [message.content, message.isComplete, message.id, message.role]);

  const handleToggleChecklistItem = useCallback((id: string) => {
    setChecklist(prev => 
      prev.map(item => 
        item.id === id ? { ...item, isCompleted: !item.isCompleted } : item
      )
    );
  }, []);

  useEffect(() => {
    messageContentRef.current = message.content;
    if (message.content !== messageContentRef.current) {
      audioReadyRef.current = false;
      autoPlayAttemptedRef.current = false;
    }
  }, [message.content]);

  useEffect(() => {
    const shouldAutoPlay = 
      isAssistant && 
      message.content && 
      autoPlayEnabled && 
      !autoPlayAttemptedRef.current && 
      message.isComplete;

    if (shouldAutoPlay) {
      if (initialRenderRef.current) {
        initialRenderRef.current = false;
        autoPlayAttemptedRef.current = true;
        handleAudioToggle();
      } else {
        const timeoutId = setTimeout(() => {
          if (!audioReadyRef.current) {
            audioReadyRef.current = true;
            autoPlayAttemptedRef.current = true;
            handleAudioToggle();
          }
        }, 500);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [message.content, autoPlayEnabled, message.isComplete]);

  useEffect(() => {
    setIsPlaying(audioController.currentMessageId === message.id);

    return () => {
      if (audioController.currentMessageId === message.id) {
        audioController.stop();
        setIsPlaying(false);
        setIsLoading(false);
      }
    };
  }, [message.id]);

  const handleAudioToggle = async () => {
    if (isLoading || audioController.isProcessing || !message.content) return;

    if (isPlaying) {
      audioController.stop();
      setIsPlaying(false);
      setIsLoading(false);
      return;
    }

    audioController.stop();
    audioController.isProcessing = true;
    setIsLoading(true);

    try {
      const audioBuffer = await synthesizeSpeech(message.content);

      if (!audioBuffer || audioBuffer.byteLength === 0) {
        throw new Error('Invalid audio data received');
      }

      const blob = new Blob([audioBuffer], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);

      const handleEnd = () => {
        audioController.stop();
        setIsPlaying(false);
        setIsLoading(false);
        retryCountRef.current = 0;
      };

      const handleError = async (e: Event) => {
        console.error('Audio playback error:', e);
        if (retryCountRef.current < maxRetries) {
          retryCountRef.current++;
          console.log(`Retrying playback (attempt ${retryCountRef.current}/${maxRetries})...`);
          handleEnd();
          await new Promise(resolve => setTimeout(resolve, 1000));
          handleAudioToggle();
        } else {
          console.error('Max retry attempts reached');
          handleEnd();
        }
      };

      audio.onended = handleEnd;
      audio.onerror = handleError;

      try {
        await audio.play();
        audioController.currentAudio = audio;
        audioController.currentUrl = url;
        audioController.currentMessageId = message.id;
        audioController.isProcessing = false;
        setIsPlaying(true);
        setIsLoading(false);
      } catch (error) {
        handleError(new Event('error'));
      }
    } catch (error) {
      if (retryCountRef.current < maxRetries) {
        retryCountRef.current++;
        console.log(`Retrying after error (attempt ${retryCountRef.current}/${maxRetries})...`);
        setIsLoading(false);
        audioController.isProcessing = false;
        await new Promise(resolve => setTimeout(resolve, 1000));
        handleAudioToggle();
      } else {
        console.error('Failed to play audio:', error);
        setIsLoading(false);
        audioController.isProcessing = false;
      }
    }
  };

  if (isAssistant && !message.content) {
    return <MessageSkeleton />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`group flex gap-4 p-4 rounded-2xl transition-all duration-500 ${
        isAssistant 
          ? 'bg-white/50 hover:bg-white/80 dark:bg-gray-800/30 dark:hover:bg-gray-800/50 shadow-sm hover:shadow-md' 
          : 'hover:bg-white/30 dark:hover:bg-gray-900/30'
      }`}
    >
      <div className={`flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-xl transition-colors ${
        isAssistant 
          ? 'bg-gradient-to-br from-blue-500/10 to-blue-600/10 dark:from-blue-400/10 dark:to-blue-500/10 text-blue-600 dark:text-blue-400' 
          : 'bg-gradient-to-br from-gray-900/10 to-gray-800/10 dark:from-white/10 dark:to-gray-200/10 text-gray-900 dark:text-white'
      }`}>
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        >
          {isAssistant ? <Bot size={18} /> : <User size={18} />}
        </motion.div>
      </div>

      <div className="flex-1 space-y-3">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
            {isAssistant ? 'DMV Plus' : 'You'}
          </p>
          {isAssistant && message.content && (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleAudioToggle}
              className={`p-1.5 rounded-xl transition-colors ${
                isPlaying
                  ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                  : 'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white'
              }`}
              title={isPlaying ? "Stop audio" : "Play audio"}
              aria-label={isPlaying ? "Stop audio playback" : "Play audio"}
            >
              {isLoading ? (
                <Loader2 size={18} className="animate-spin" />
              ) : isPlaying ? (
                <Pause size={18} />
              ) : (
                <Volume2 size={18} />
              )}
            </motion.button>
          )}
        </div>

        <div className="space-y-4">
          <div className="text-gray-900 dark:text-gray-100 leading-relaxed">
            {formatMessageContent(message.content)}
          </div>

          {isAssistant && message.isComplete && diagramRef.current && !showDiagram && (
            <div className="mt-4">
              <button
                onClick={() => setShowDiagram(true)}
                className="inline-flex items-center gap-2 px-3 py-1.5 text-sm bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-lg hover:bg-blue-100 dark:hover:bg-blue-900/50 transition-colors"
              >
                <GitBranch size={16} />
                View Process Diagram
              </button>
            </div>
          )}
          
          {isLoadingChecklist ? (
            <ChecklistSkeleton />
          ) : checklist.length > 0 && (
            <div className="mt-2">
              <Checklist items={checklist} onToggle={handleToggleChecklistItem} />
            </div>
          )}

          <AnimatePresence>
            {showDiagram && diagramRef.current && (
              <DiagramViewer
                diagram={diagramRef.current}
                isOpen={showDiagram}
                onClose={() => setShowDiagram(false)}
              />
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isAssistant && onSuggestionSelect && message.isComplete && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className="mt-4"
              >
                {isStateRequest ? (
                  <StateSelector onSelect={onSuggestionSelect} />
                ) : isAgeRequest(message.content) ? (
                  <AgePicker onSelect={(age) => onSuggestionSelect(age.toString())} />
                ) : suggestions && suggestions.length > 0 ? (
                  <SuggestionBubbles
                    suggestions={suggestions}
                    onSelect={onSuggestionSelect}
                  />
                ) : null}
              </motion.div>
            )}
          </AnimatePresence>

          {isAssistant && message.isComplete && !isInitialMessage && (
            <div className="mt-4">
              <FeedbackButtons 
                messageId={message.id} 
                content={message.content} 
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}