import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Lock, Loader2 } from 'lucide-react';
import { format } from 'date-fns';
import { FeedbackService } from '@/services/feedbackService';
import type { Feedback } from '@/types/feedback';
import { StatsOverview } from '@/components/admin/StatsOverview';
import { FeedbackCharts } from '@/components/admin/FeedbackCharts';
import { FeedbackControls } from '@/components/admin/FeedbackControls';
import { FeedbackList } from '@/components/admin/FeedbackList';
import { DeleteConfirmationModal } from '@/components/admin/DeleteConfirmationModal';

const ADMIN_PASSWORD = 'dmv-plus-admin-2024!';

export function AdminDashboard() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<Feedback[]>([]);
  const [timeRange, setTimeRange] = useState('all');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedAuth = sessionStorage.getItem('dmv_admin_auth');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
      loadFeedback();
    } else {
      setIsLoading(false);
    }
  }, [timeRange, selectedDate]);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
      sessionStorage.setItem('dmv_admin_auth', 'true');
      loadFeedback();
    } else {
      setError('Invalid password');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem('dmv_admin_auth');
  };

  const loadFeedback = async () => {
    setIsLoading(true);
    try {
      let options: { timeRange?: string; date?: string } = {};
      
      if (timeRange !== 'all') {
        options.timeRange = timeRange;
      }
      
      if (selectedDate) {
        options.date = format(selectedDate, 'yyyy-MM-dd');
      }
      
      const data = await FeedbackService.getFeedback(options);
      setFeedback(data);
    } catch (error) {
      console.error('Error loading feedback:', error);
      setError('Failed to load feedback data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFeedback = async () => {
    setIsLoading(true);
    try {
      await FeedbackService.clearAllFeedback();
      setFeedback([]);
      setShowConfirmDelete(false);
    } catch (error) {
      console.error('Error clearing feedback:', error);
      setError('Failed to clear feedback data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = () => {
    const csvContent = [
      ['Message ID', 'Content', 'Is Positive', 'Comment', 'Timestamp', 'URL'].join(','),
      ...feedback.map(item => [
        item.message_id,
        `"${item.content.replace(/"/g, '""')}"`,
        item.is_positive,
        `"${item.comment?.replace(/"/g, '""') || ''}"`,
        item.timestamp,
        `"${item.url}"`
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `dmv-plus-feedback-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center px-4">
        <div className="w-full max-w-md">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex items-center justify-center mb-8">
              <div className="p-3 rounded-full bg-blue-100 dark:bg-blue-900/30">
                <Lock className="h-6 w-6 text-blue-600 dark:text-blue-400" />
              </div>
            </div>
            
            <h2 className="text-2xl font-semibold text-center text-gray-900 dark:text-white mb-6">
              Admin Dashboard Login
            </h2>

            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
                  placeholder="Enter admin password"
                />
              </div>

              {error && (
                <p className="text-sm text-red-600 dark:text-red-400">
                  {error}
                </p>
              )}

              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
              >
                Login
              </button>

              <Link 
                to="/"
                className="block text-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white mt-4"
              >
                ← Back to Chat
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }

  const positiveCount = feedback.filter(item => item.is_positive).length;
  const negativeCount = feedback.filter(item => !item.is_positive).length;
  const totalCount = feedback.length;
  const positivePercentage = totalCount > 0 ? (positiveCount / totalCount) * 100 : 0;

  const chartData = {
    labels: ['Positive', 'Negative'],
    datasets: [
      {
        data: [positiveCount, negativeCount],
        backgroundColor: ['#22c55e', '#ef4444'],
        borderColor: ['#16a34a', '#dc2626'],
        borderWidth: 1,
      },
    ],
  };

  const timelineData = {
    labels: feedback
      .slice(-10)
      .map(item => format(new Date(item.timestamp), 'MMM d, h:mm a')),
    datasets: [
      {
        label: 'Feedback Timeline',
        data: feedback.slice(-10).map(item => (item.is_positive ? 1 : -1)),
        backgroundColor: feedback
          .slice(-10)
          .map(item => (item.is_positive ? '#22c55e' : '#ef4444')),
        borderColor: feedback
          .slice(-10)
          .map(item => (item.is_positive ? '#16a34a' : '#dc2626')),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link 
                to="/"
                className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors"
              >
                <ArrowLeft size={20} />
                <span>Back to Chat</span>
              </Link>
            </div>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              DMV Plus Admin Dashboard
            </h1>
            <button
              onClick={handleLogout}
              className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors"
            >
              Logout
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <Loader2 className="animate-spin h-8 w-8 text-blue-600" />
          </div>
        ) : (
          <div className="space-y-8">
            <StatsOverview
              totalCount={totalCount}
              positivePercentage={positivePercentage}
            />

            <FeedbackCharts
              distributionData={chartData}
              timelineData={timelineData}
            />

            <FeedbackControls
              timeRange={timeRange}
              selectedDate={selectedDate}
              onTimeRangeChange={setTimeRange}
              onDateChange={setSelectedDate}
              onExport={handleExport}
              onClearAll={() => setShowConfirmDelete(true)}
            />

            <FeedbackList feedback={feedback} />
          </div>
        )}
      </main>

      <DeleteConfirmationModal
        isOpen={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={handleClearFeedback}
      />
    </div>
  );
}