import RecordRTC from 'recordrtc';
import OpenAI from 'openai';

export class AudioService {
  private static openai: OpenAI | null = null;
  private static audioContext: AudioContext | null = null;
  private static mediaRecorder: MediaRecorder | null = null;
  private static chunks: Blob[] = [];

  private static getOpenAI(): OpenAI {
    if (!this.openai) {
      if (!import.meta.env.VITE_OPENAI_API_KEY) {
        throw new Error('OpenAI API key is not configured');
      }
      this.openai = new OpenAI({
        apiKey: import.meta.env.VITE_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });
    }
    return this.openai;
  }

  static async setupRecorder(stream: MediaStream): Promise<RecordRTC> {
    try {
      if (!stream.active) {
        throw new Error('Audio stream is not active');
      }

      // Ensure any previous instances are cleaned up
      await this.cleanup();
      this.chunks = [];

      // Initialize audio context for processing
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 16000,
        latencyHint: 'interactive'
      });

      // Resume audio context if suspended
      if (this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
      }

      const recorder = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/wav',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        desiredSampRate: 16000,
        timeSlice: 250,
        checkForInactiveTracks: true,
        audioBitsPerSecond: 128000,
        disableLogs: true,
        ondataavailable: (blob: Blob) => {
          if (blob && blob.size > 0) {
            this.chunks.push(blob);
          }
        },
      });

      // Verify recorder initialization
      if (!recorder.getState()) {
        throw new Error('Failed to initialize recorder');
      }

      return recorder;
    } catch (error) {
      await this.cleanup();
      throw error;
    }
  }

  static async getMediaStream(): Promise<MediaStream> {
    if (!navigator.mediaDevices?.getUserMedia) {
      throw new Error('Audio recording is not supported in this browser');
    }

    try {
      // Request permission first
      const permission = await navigator.permissions.query({ name: 'microphone' as PermissionName });
      if (permission.state === 'denied') {
        throw new Error('Microphone access is blocked. Please allow access in your browser settings.');
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: { ideal: 16000 },
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });

      if (!stream.active || stream.getAudioTracks().length === 0) {
        throw new Error('No active audio input detected');
      }

      // Verify audio track is working
      const audioTrack = stream.getAudioTracks()[0];
      if (!audioTrack || !audioTrack.enabled) {
        throw new Error('Audio track is not enabled');
      }

      return stream;
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'NotAllowedError') {
          throw new Error('Please allow microphone access to record audio');
        }
        if (error.name === 'NotFoundError') {
          throw new Error('No microphone found. Please check your audio settings');
        }
        if (error.name === 'NotReadableError') {
          throw new Error('Microphone is busy or not responding');
        }
        if (error.name === 'AbortError') {
          throw new Error('Recording was cancelled');
        }
        if (error.name === 'SecurityError') {
          throw new Error('Microphone access is blocked by your browser settings');
        }
        throw new Error(error.message);
      }
      throw new Error('Failed to access microphone');
    }
  }

  static async transcribeAudio(audioBlob: Blob): Promise<string> {
    try {
      if (!audioBlob || audioBlob.size === 0) {
        throw new Error('No audio data received');
      }

      // Create a File object from the Blob
      const audioFile = new File([audioBlob], 'audio.wav', { 
        type: 'audio/wav',
        lastModified: Date.now()
      });

      // Use standard Whisper API for transcription
      const response = await this.getOpenAI().audio.transcriptions.create({
        file: audioFile,
        model: 'whisper-1',
        language: 'en',
        response_format: 'text',
        temperature: 0.3,
        prompt: 'This is a conversation about DMV-related topics.',
      });

      if (!response || typeof response !== 'string' || response.trim().length === 0) {
        throw new Error('No speech detected. Please try speaking more clearly.');
      }

      return response;
    } catch (error) {
      console.error('Transcription error:', error);
      
      if (error instanceof Error) {
        if (error.message.includes('401')) {
          throw new Error('Authentication failed. Please check your API key');
        }
        if (error.message.includes('429')) {
          throw new Error('Too many requests. Please try again in a moment');
        }
        if (error.message.includes('No speech detected')) {
          throw new Error('No speech detected. Please try speaking more clearly.');
        }
        throw new Error(error.message);
      }
      
      throw new Error('Failed to transcribe audio. Please try again.');
    }
  }

  static async cleanup(stream?: MediaStream | null, recorder?: RecordRTC | null): Promise<void> {
    try {
      if (this.audioContext) {
        await this.audioContext.close();
      }
      this.audioContext = null;
      this.chunks = [];

      // Clean up stream and recorder
      if (stream) {
        const tracks = stream.getTracks();
        for (const track of tracks) {
          try {
            track.stop();
            stream.removeTrack(track);
          } catch (error) {
            console.warn('Error stopping track:', error);
          }
        }
      }

      if (recorder) {
        try {
          recorder.stopRecording();
          await new Promise<void>((resolve) => {
            setTimeout(() => {
              try {
                recorder.destroy();
                recorder.clearRecordedData();
              } catch (error) {
                console.warn('Error cleaning up recorder:', error);
              }
              resolve();
            }, 100);
          });
        } catch (error) {
          console.warn('Error stopping recorder:', error);
        }
      }
    } catch (error) {
      console.warn('Error during cleanup:', error);
    }
  }
}