import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface SuggestionBubblesProps {
  suggestions: string[];
  onSelect: (suggestion: string) => void;
}

export function SuggestionBubbles({ suggestions, onSelect }: SuggestionBubblesProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-wrap gap-2 mt-2"
    >
      <AnimatePresence>
        {suggestions.map((suggestion, index) => (
          <motion.button
            key={suggestion}
            onClick={() => onSelect(suggestion)}
            className="px-4 py-2 text-sm rounded-xl bg-white dark:bg-gray-900 border border-gray-200/50 dark:border-gray-800/50 text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 hover:border-gray-300 dark:hover:border-gray-700 transition-all duration-200 shadow-sm hover:shadow-md"
            initial={{ opacity: 0, scale: 0.9, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: -20 }}
            transition={{ 
              duration: 0.2,
              delay: index * 0.05,
              type: "spring",
              stiffness: 400,
              damping: 25
            }}
            whileHover={{ 
              scale: 1.05,
              transition: { duration: 0.2 }
            }}
            whileTap={{ scale: 0.95 }}
          >
            {suggestion}
          </motion.button>
        ))}
      </AnimatePresence>
    </motion.div>
  );
}