import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import type { ChartData } from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface FeedbackChartsProps {
  distributionData: ChartData<'pie'>;
  timelineData: ChartData<'bar'>;
}

export function FeedbackCharts({ distributionData, timelineData }: FeedbackChartsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Feedback Distribution
        </h3>
        <div className="h-64">
          <Pie data={distributionData} options={{ maintainAspectRatio: false }} />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Recent Feedback Timeline
        </h3>
        <div className="h-64">
          <Bar 
            data={timelineData} 
            options={{
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  max: 1,
                  min: -1,
                  ticks: {
                    callback: value => value === 1 ? 'Positive' : value === -1 ? 'Negative' : '',
                  },
                },
              },
            }} 
          />
        </div>
      </div>
    </div>
  );
}