import React, { useState, useRef, useCallback } from 'react';
import { Mic, Square, Loader2, AlertCircle, Radio } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { AudioService } from '../services/audioService';
import type RecordRTC from 'recordrtc';

interface AudioRecorderProps {
  onRecordingComplete: (audioBlob: Blob) => void;
  isProcessing?: boolean;
  className?: string;
}

export function AudioRecorder({ onRecordingComplete, isProcessing, className = '' }: AudioRecorderProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const recorderRef = useRef<RecordRTC>();
  const streamRef = useRef<MediaStream>();
  const timerRef = useRef<number>();

  const cleanup = useCallback(() => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
      timerRef.current = undefined;
    }

    AudioService.cleanup(streamRef.current, recorderRef.current);
    streamRef.current = undefined;
    recorderRef.current = undefined;
    setRecordingDuration(0);
    setIsRecording(false);
  }, []);

  const startRecording = useCallback(async () => {
    try {
      setError(null);
      cleanup();

      const stream = await AudioService.getMediaStream();
      streamRef.current = stream;
      
      const recorder = await AudioService.setupRecorder(stream);
      recorderRef.current = recorder;

      recorder.startRecording();
      setIsRecording(true);

      let duration = 0;
      timerRef.current = window.setInterval(() => {
        duration += 1;
        setRecordingDuration(duration);
        if (duration >= 60) {
          stopRecording();
        }
      }, 1000);

    } catch (error) {
      console.error('Error starting recording:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to start recording');
      }
      cleanup();
    }
  }, [cleanup]);

  const stopRecording = useCallback(() => {
    if (!recorderRef.current) {
      setError('Recording system not initialized');
      cleanup();
      return;
    }

    try {
      recorderRef.current.stopRecording(async () => {
        try {
          const blob = recorderRef.current!.getBlob();
          
          if (!blob || blob.size === 0) {
            throw new Error('No audio recorded');
          }

          if (recordingDuration < 1) {
            throw new Error('Recording too short. Please speak for at least 1 second.');
          }

          onRecordingComplete(blob);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
          } else {
            setError('Failed to process recording');
          }
        } finally {
          cleanup();
        }
      });
    } catch (error) {
      console.error('Error stopping recording:', error);
      setError('Failed to stop recording');
      cleanup();
    }
  }, [cleanup, onRecordingComplete, recordingDuration]);

  React.useEffect(() => {
    return cleanup;
  }, [cleanup]);

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <motion.button
        onClick={isRecording ? stopRecording : startRecording}
        className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-xl transition-colors ${
          isRecording
            ? 'bg-red-100 dark:bg-red-900/30 text-red-600 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/50'
            : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
        }`}
        disabled={isProcessing}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <motion.div
          animate={isRecording ? { 
            scale: [1, 1.2, 1], 
            transition: { repeat: Infinity, duration: 1.5 } 
          } : {}}
          className="relative"
        >
          {isRecording ? (
            <>
              <Square size={16} className="fill-current" />
              <motion.div
                className="absolute -right-1 -top-1 w-2 h-2 rounded-full bg-red-500"
                animate={{
                  opacity: [1, 0.5],
                  scale: [1, 1.2],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                }}
              />
            </>
          ) : (
            <Mic size={16} />
          )}
        </motion.div>
        <span className="text-sm">
          {isRecording ? (
            <div className="flex items-center gap-2">
              <span>Stop ({recordingDuration}s)</span>
              <Radio size={14} className="animate-pulse" />
            </div>
          ) : (
            "Voice"
          )}
        </span>
      </motion.button>

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="text-sm text-red-600 dark:text-red-400 flex items-center gap-2"
          >
            <AlertCircle size={14} />
            <span>{error}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}