import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChecklistItem } from './ChecklistItem';
import type { ChecklistItem as ChecklistItemType } from '@/types';

interface ChecklistContentProps {
  items: ChecklistItemType[];
  isExpanded: boolean;
  onToggle: (id: string) => void;
}

export function ChecklistContent({ items, isExpanded, onToggle }: ChecklistContentProps) {
  return (
    <AnimatePresence>
      {isExpanded && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
          className="space-y-2 mt-2"
        >
          {items.map((item) => (
            <ChecklistItem
              key={item.id}
              item={item}
              onToggle={onToggle}
            />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}