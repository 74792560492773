import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Globe, Award, MessageSquare } from 'lucide-react';

interface TrustBadgesProps {
  onDismiss: () => void;
}

export function TrustBadges({ onDismiss }: TrustBadgesProps) {
  return (
    <div className="max-w-[1080px] mx-auto px-4 md:px-8 py-16 md:py-24 text-center">
      <div className="inline-flex flex-col md:flex-row gap-4 md:gap-12 py-6 md:py-8 px-6 md:px-12 bg-gray-50 rounded-2xl md:rounded-3xl mb-12 md:mb-16">
        {[
          { icon: Shield, text: 'Secure & Private' },
          { icon: Globe, text: '50 States Coverage' },
          { icon: Award, text: 'Official Information' }
        ].map((item) => (
          <div key={item.text} className="flex items-center justify-center gap-2 text-gray-900 py-2">
            <item.icon size={18} className="text-blue-600" />
            <span className="font-medium">{item.text}</span>
          </div>
        ))}
      </div>

      <div className="space-y-6">
        <motion.button
          onClick={onDismiss}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="inline-flex items-center gap-2 px-8 py-4 bg-blue-600 text-white rounded-full text-lg font-medium hover:bg-blue-700 transition-colors"
        >
          <MessageSquare size={20} />
          Get Started Now
        </motion.button>
        <p className="text-sm text-gray-500">
          Join thousands of satisfied users who simplified their DMV experience
        </p>
      </div>
    </div>
  );
}