import React, { useState } from 'react';
import { DiagramViewer } from './DiagramViewer';
import { motion } from 'framer-motion';
import { Play, AlertCircle } from 'lucide-react';

const TEST_DIAGRAMS = [
  {
    name: 'Simple Process',
    content: `graph TD
      A["Start Process"] --> B["Step 1"]
      B --> C["Step 2"]
      C --> D["End Process"]
      classDef default fill:#f8fafc,stroke:#64748b,stroke-width:2px
      classDef start fill:#e3f2fd,stroke:#1e88e5,stroke-width:2px
      classDef end fill:#e8f5e9,stroke:#43a047,stroke-width:2px
      class A start
      class D end`
  },
  {
    name: 'License Process',
    content: `graph TD
      A["Check Eligibility"] --> B["Submit Application"]
      B --> C["Provide Documents"]
      C --> D["Take Tests"]
      D --> E["Receive License"]
      classDefs default fill:#f8fafc,stroke:#64748b,stroke-width:2px
      classDef start fill:#e3f2fd,stroke:#1e88e5,stroke-width:2px
      classDef end fill:#e8f5e9,stroke:#43a047,stroke-width:2px
      class A start
      class E end`
  },
  {
    name: 'Complex Process',
    content: `graph TD
      A["Start"] --> B{"Document Check"}
      B -->|"Complete"| C["Process Application"]
      B -->|"Incomplete"| D["Request Documents"]
      D --> B
      C --> E["Final Review"]
      E --> F["Final Approval"]
      classDef default fill:#f8fafc,stroke:#64748b,stroke-width:2px
      classDef start fill:#e3f2fd,stroke:#1e88e5,stroke-width:2px
      classDef end fill:#e8f5e9,stroke:#43a047,stroke-width:2px
      class A start
      class F end`
  }
];

export function DiagramTest() {
  const [selectedDiagram, setSelectedDiagram] = useState<string | null>(null);
  const [customDiagram, setCustomDiagram] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleTestDiagram = (diagram: string) => {
    try {
      setError(null);
      setSelectedDiagram(diagram);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm"
        >
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
            Diagram Test Suite
          </h1>

          {/* Predefined Tests */}
          <div className="space-y-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              Predefined Tests
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {TEST_DIAGRAMS.map((diagram) => (
                <motion.button
                  key={diagram.name}
                  onClick={() => handleTestDiagram(diagram.content)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="p-4 bg-blue-50 dark:bg-blue-900/30 rounded-xl hover:bg-blue-100 dark:hover:bg-blue-900/50 transition-colors text-left"
                >
                  <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
                    <Play size={16} />
                    <span className="font-medium">{diagram.name}</span>
                  </div>
                </motion.button>
              ))}
            </div>
          </div>

          {/* Custom Input */}
          <div className="mt-8 space-y-4">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              Custom Diagram
            </h2>
            <textarea
              value={customDiagram}
              onChange={(e) => setCustomDiagram(e.target.value)}
              placeholder="Enter Mermaid diagram code..."
              className="w-full h-48 px-4 py-3 rounded-xl bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent"
            />
            <div className="flex justify-end">
              <motion.button 
                onClick={() => handleTestDiagram(customDiagram)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={!customDiagram.trim()}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
              >
                Test Custom Diagram
              </motion.button>
            </div>
          </div>

          {/* Error Display */}
          {error && (
            <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/30 rounded-xl text-red-600 dark:text-red-400 flex items-center gap-2">
              <AlertCircle size={16} className="flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}
        </motion.div>

        {/* Diagram Preview */}
        {selectedDiagram && (
          <DiagramViewer
            diagram={selectedDiagram.trim()}
            isOpen={true}
            onClose={() => setSelectedDiagram(null)}
          />
        )}
      </div>
    </div>
  );
}