import OpenAI from 'openai';
import { formatDiagramContent } from './diagramUtils';
import type { ChatContext } from '../types';

const SYSTEM_PROMPT = `You are a friendly and knowledgeable DMV Plus assistant. Your goal is to provide accurate, helpful information while maintaining a natural conversation flow.

GUIDELINES:
1. CRITICAL: ALWAYS gather essential context before providing detailed guidance
   - For ANY question, first understand the user's specific situation
   - Ask ONE question at a time and wait for the response
   - Common context needed:
     * State (for all DMV services)
     * Age (for licenses, permits)
     * Current status (new/renewal/replacement)
     * Vehicle type (for registration)
     * Timeline/urgency
     * Special circumstances

2. Be conversational and friendly while maintaining professionalism

3. Format options consistently:
   (Options: First option|Second option|Third option)

   Examples:
   - (Options: Yes|No)
   - (Options: New license|Renewal|Replacement)
   - (Options: First-time permit|Permit renewal)
   - (Options: Passenger vehicle|Motorcycle|Commercial vehicle)

4. Keep responses focused and clear

5. Only after gathering necessary context, for multi-step processes:
    a. First explain each step with details and context
    b. Use numbered steps with clear titles
    c. Format each step as "1. Step Title: Description"
    d. Keep steps consistent and clear
    e. End with "Would you like more details about any of these steps?"

Example Context Gathering:
User: "How do I get a driver's license?"
Assistant: "I'll help you get the right information about driver's licenses. First, which state are you in?"

User: "California"
Assistant: "Thanks! And what's your age? This will help me provide the correct requirements and process."

User: "17"
Assistant: "Are you applying for your first license, or do you have a permit already? (Options: First license|Have permit)"

User: "First license"
Assistant: "Perfect, I'll explain the process for getting your first driver's license in California at age 17..."

IMPORTANT: Never skip context gathering - it's essential for providing accurate, relevant guidance.`;

function validateApiKey(): string {
  const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
  if (!apiKey) {
    throw new Error('OpenAI API key is not configured. Please check your environment variables.');
  }
  return apiKey;
}

function createOpenAIClient(): OpenAI {
  const apiKey = validateApiKey();
  return new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  });
}

export async function* generateResponseStream(
  message: string,
  context: ChatContext,
  signal?: AbortSignal
) {
  if (!message.trim()) {
    throw new Error('Message cannot be empty');
  }

  const openai = createOpenAIClient();

  try {
    const stream = await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [
        { role: 'system', content: SYSTEM_PROMPT },
        ...context.messages.map(msg => ({
          role: msg.role as 'user' | 'assistant',
          content: msg.content
        })),
        { role: 'user', content: message }
      ],
      stream: true,
      temperature: 0.7,
      max_tokens: 500,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });

    let hasYieldedContent = false;

    for await (const chunk of stream) {
      if (signal?.aborted) {
        throw new Error('Request aborted');
      }
      
      const content = chunk.choices[0]?.delta?.content || '';
      if (content) {
        hasYieldedContent = true;
        yield content;
      }
    }

    if (!hasYieldedContent) {
      throw new Error('No response generated');
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('401')) {
        throw new Error('Invalid API key. Please check your OpenAI API key configuration.');
      }
      if (error.message.includes('429')) {
        throw new Error('Rate limit exceeded. Please try again in a moment.');
      }
      if (error.message.includes('500')) {
        throw new Error('OpenAI service error. Please try again.');
      }
      if (error.message === 'Request aborted') {
        throw error;
      }
      throw new Error(`Chat error: ${error.message}`);
    }
    throw new Error('An unexpected error occurred during the chat');
  }
}

export async function synthesizeSpeech(text: string): Promise<ArrayBuffer> {
  if (!text.trim()) {
    throw new Error('Text cannot be empty');
  }

  const openai = createOpenAIClient();

  try {
    const response = await openai.audio.speech.create({
      model: 'tts-1',
      voice: 'nova',
      input: text,
      speed: 1.0,
      response_format: 'mp3',
    });

    const arrayBuffer = await response.arrayBuffer();
    if (!arrayBuffer || arrayBuffer.byteLength === 0) {
      throw new Error('Failed to generate speech');
    }

    return arrayBuffer;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('401')) {
        throw new Error('Invalid API key. Please check your OpenAI API key configuration.');
      }
      if (error.message.includes('429')) {
        throw new Error('Rate limit exceeded. Please try again in a moment.');
      }
      if (error.message.includes('500')) {
        throw new Error('OpenAI service error. Please try again.');
      }
      throw new Error(`Speech synthesis error: ${error.message}`);
    }
    throw new Error('An unexpected error occurred during speech synthesis');
  }
}