import React, { useState, useRef, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Car, Moon, Sun } from 'lucide-react';
import { ChatMessage } from '../components/ChatMessage';
import { ChatInput } from '../components/ChatInput';
import { QuickActions } from '../components/QuickActions';
import { ChatControls } from '../components/ChatControls';
import { WelcomeOverlay } from '../components/WelcomeOverlay';
import { AudioService } from '../services/audioService';
import { generateResponseStream } from '../utils/openai';
import { StreamParser } from '../utils/contextManager';
import { getTimeBasedGreeting } from '../utils/timeUtils';
import { Disclaimer } from '../components/Disclaimer';
import type { ChatState, Message, ChatContext } from '../types';

const greeting = getTimeBasedGreeting();

const INITIAL_MESSAGE: Message = {
  id: '1',
  role: 'assistant',
  content: `${greeting}! I'm your DMV Plus assistant. How can I help you today?`,
  timestamp: new Date(),
  isComplete: true,
};

const INITIAL_CONTEXT: ChatContext = {
  previousTopics: [],
  messages: [INITIAL_MESSAGE],
};

export function ChatApp() {
  const [showOverlay, setShowOverlay] = useState(true);
  const [chatState, setChatState] = useState<ChatState>({
    messages: [INITIAL_MESSAGE],
    isLoading: false,
    context: INITIAL_CONTEXT,
  });
  const [isProcessingAudio, setIsProcessingAudio] = useState(false);
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  });
  const [showQuickActions, setShowQuickActions] = useState(true);
  const [hasWelcomeBeenDismissed, setHasWelcomeBeenDismissed] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const parserRef = useRef<StreamParser>(new StreamParser(INITIAL_CONTEXT));
  const abortControllerRef = useRef<AbortController>();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastMessageIdRef = useRef<string>(INITIAL_MESSAGE.id);
  const mainRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const resetScroll = useCallback(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = 0;
    }

    if (mainRef.current) {
      mainRef.current.scrollTop = 0;
    }
  }, []);

  const handleDismissOverlay = useCallback(() => {
    resetScroll();
    setShowOverlay(false);
    setHasWelcomeBeenDismissed(true);
    setChatState({
      messages: [INITIAL_MESSAGE],
      isLoading: false,
      context: INITIAL_CONTEXT,
    });
    setShowQuickActions(true);

    requestAnimationFrame(() => {
      resetScroll();
      requestAnimationFrame(resetScroll);
    });
  }, [resetScroll]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatState.messages]);

  useEffect(() => {
    if (!showOverlay && hasWelcomeBeenDismissed) {
      resetScroll();
    }
  }, [showOverlay, hasWelcomeBeenDismissed, resetScroll]);

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [showOverlay]);

  const handleAudioInput = useCallback(async (audioBlob: Blob) => {
    setIsProcessingAudio(true);
    try {
      const text = await AudioService.transcribeAudio(audioBlob);
      if (!text) {
        throw new Error('No speech detected');
      }
      await handleSendMessage(text);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert('Error processing audio. Please try again or use text input.');
      }
    } finally {
      setIsProcessingAudio(false);
    }
  }, []);

  const handleSendMessage = async (content: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setShowQuickActions(false);
    setError(null);

    const messageId = Date.now().toString();
    const userMessage: Message = {
      id: messageId,
      role: 'user',
      content,
      timestamp: new Date(),
      isComplete: true,
    };

    const assistantMessage: Message = {
      id: (Date.now() + 1).toString(),
      role: 'assistant',
      content: '',
      timestamp: new Date(),
    };

    lastMessageIdRef.current = assistantMessage.id;

    setChatState((prev) => {
      const newMessages = [...prev.messages, userMessage];
      const newContext = {
        ...prev.context,
        messages: newMessages,
      };

      parserRef.current.addMessage(userMessage);

      return {
        messages: [...newMessages, assistantMessage],
        isLoading: true,
        context: newContext,
      };
    });

    try {
      if (!import.meta.env.VITE_OPENAI_API_KEY) {
        throw new Error('OpenAI API key is not configured. Please check your environment variables.');
      }

      abortControllerRef.current = new AbortController();
      let accumulatedContent = '';

      const stream = generateResponseStream(
        content,
        parserRef.current.getContext(),
        abortControllerRef.current.signal
      );

      for await (const chunk of stream) {
        if (abortControllerRef.current.signal.aborted) break;

        accumulatedContent += chunk;
        const parsed = parserRef.current.update(accumulatedContent);

        setChatState((prev) => {
          const updatedMessages = prev.messages.map((msg) =>
            msg.id === assistantMessage.id
              ? {
                  ...msg,
                  content: parsed.text,
                  suggestions: parsed.options,
                  isComplete: false,
                }
              : msg
          );

          return {
            ...prev,
            messages: updatedMessages,
          };
        });
      }

      setChatState((prev) => {
        const updatedMessages = prev.messages.map((msg) =>
          msg.id === assistantMessage.id
            ? {
                ...msg,
                isComplete: true,
              }
            : msg
        );

        const completedMessage = updatedMessages.find(
          (msg) => msg.id === assistantMessage.id
        );
        if (completedMessage) {
          parserRef.current.addMessage(completedMessage);
        }

        return {
          ...prev,
          messages: updatedMessages,
          isLoading: false,
          context: parserRef.current.getContext(),
        };
      });
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        return;
      }
      
      console.error('Stream error:', error);

      let errorMessage = 'An unexpected error occurred. Please try again.';
      if (error instanceof Error) {
        if (error.message.includes('API key')) {
          errorMessage = 'API key configuration error. Please check your settings.';
        } else if (error.message.includes('429')) {
          errorMessage = 'Too many requests. Please wait a moment and try again.';
        } else if (error.message.includes('network')) {
          errorMessage = 'Network error. Please check your internet connection.';
        } else {
          errorMessage = error.message;
        }
      }

      setChatState((prev) => ({
        ...prev,
        messages: prev.messages.map((msg) =>
          msg.id === assistantMessage.id
            ? {
                ...msg,
                content: `I apologize, but I encountered an error: ${errorMessage}`,
                isComplete: true,
              }
            : msg
        ),
        isLoading: false,
      }));
    }
  };

  const handleShare = useCallback(async () => {
    const text = chatState.messages
      .map(msg => `${msg.role === 'assistant' ? 'DMV Plus' : 'You'}: ${msg.content}`)
      .join('\n\n');

    try {
      if (navigator.share && navigator.canShare) {
        const shareData = {
          title: 'DMV Plus Conversation',
          text: text
        };

        if (navigator.canShare(shareData)) {
          await navigator.share(shareData);
          return;
        }
      }

      await navigator.clipboard.writeText(text);
      alert('Conversation copied to clipboard!');
    } catch (error) {
      console.error('Share failed:', error);
      
      try {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('Conversation copied to clipboard!');
      } catch (fallbackError) {
        console.error('Clipboard fallback failed:', fallbackError);
        alert('Unable to share or copy. Please try again or use the export option instead.');
      }
    }
  }, [chatState.messages]);

  const handleExport = useCallback(() => {
    const text = chatState.messages
      .map(msg => `${msg.role === 'assistant' ? 'DMV Plus' : 'You'}: ${msg.content}`)
      .join('\n\n');
    
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'dmv-plus-conversation.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [chatState.messages]);

  const handlePrint = useCallback(() => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    const content = chatState.messages
      .map(msg => `
        <div style="margin-bottom: 20px;">
          <div style="font-weight: bold; margin-bottom: 5px;">
            ${msg.role === 'assistant' ? 'DMV Plus' : 'You'}:
          </div>
          <div style="white-space: pre-wrap;">${msg.content}</div>
        </div>
      `)
      .join('');

    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>DMV Plus Conversation</title>
          <style>
            body {
              font-family: system-ui, -apple-system, sans-serif;
              line-height: 1.5;
              max-width: 800px;
              margin: 40px auto;
              padding: 0 20px;
            }
            @media print {
              body { margin: 20px; }
            }
          </style>
        </head>
        <body>
          <h1>DMV Plus Conversation</h1>
          ${content}
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  }, [chatState.messages]);

  const handleReset = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    parserRef.current.reset();
    setChatState({
      messages: [INITIAL_MESSAGE],
      isLoading: false,
      context: INITIAL_CONTEXT,
    });
    setShowQuickActions(true);
  }, []);

  return (
    <>
      <WelcomeOverlay isVisible={showOverlay} onDismiss={handleDismissOverlay} />

      {hasWelcomeBeenDismissed && (
        <div 
          ref={mainRef}
          className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-950 transition-colors duration-200"
        >
          <header className="fixed top-0 left-0 right-0 bg-white/70 dark:bg-gray-900/70 backdrop-blur-md border-b border-gray-200/50 dark:border-gray-800/50 z-10">
            <div className="max-w-3xl mx-auto px-4 py-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="bg-gradient-to-br from-[#007AFE] to-[#0056b3] dark:from-[#007AFE] dark:to-[#3399ff] text-white p-1.5 rounded-lg shadow-sm">
                    <Car size={20} className="rounded-sm" />
                  </div>
                  <div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      Your fast track to DMV success
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setDarkMode(!darkMode)}
                  className="p-1.5 rounded-lg text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white transition-colors"
                  title={darkMode ? "Light mode" : "Dark mode"}
                >
                  {darkMode ? <Sun size={18} /> : <Moon size={18} />}
                </button>
              </div>
            </div>
          </header>

          <main 
            ref={chatContainerRef}
            className="max-w-3xl mx-auto px-4 pt-24 pb-48 overflow-x-hidden"
          >
            <div className="mt-4 space-y-6">
              {chatState.messages.map((message, index) => (
                <ChatMessage
                  key={message.id}
                  message={message}
                  onSuggestionSelect={
                    index === chatState.messages.length - 1 
                      ? handleSendMessage 
                      : undefined
                  }
                  autoPlayEnabled={autoPlayEnabled && message.id === lastMessageIdRef.current}
                  isInitialMessage={index === 0}
                />
              ))}
              <div ref={messagesEndRef} />
              {showQuickActions && chatState.messages.length === 1 && (
                <QuickActions onSelect={handleSendMessage} />
              )}
            </div>
          </main>

          <div className="fixed bottom-0 left-0 right-0 bg-white/70 dark:bg-gray-900/70 backdrop-blur-md border-t border-gray-200/50 dark:border-gray-800/50">
            <div className="max-w-3xl mx-auto px-4">
              <ChatControls
                autoPlayEnabled={autoPlayEnabled}
                onAutoPlayToggle={() => setAutoPlayEnabled(!autoPlayEnabled)}
                onReset={handleReset}
                onExport={handleExport}
                onShare={handleShare}
                onPrint={handlePrint}
                onAudioComplete={handleAudioInput}
                isProcessingAudio={isProcessingAudio}
              />
              <ChatInput
                onSend={handleSendMessage}
                disabled={chatState.isLoading}
                onAudioComplete={handleAudioInput}
                isProcessingAudio={isProcessingAudio}
              />
              <Disclaimer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}