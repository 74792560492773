import React from 'react';
import { AlertCircle } from 'lucide-react';

export function Disclaimer() {
  return (
    <div className="flex items-center justify-center gap-1.5 py-3 mt-2 text-[10px] text-gray-400/60 dark:text-gray-500/60 border-t border-gray-200/20 dark:border-gray-800/20">
      <AlertCircle size={10} className="flex-shrink-0 opacity-50" />
      <span>
        The assistant can make mistakes.{' '}
        <a
          href="https://help.driving-tests.org/en/articles/7897559-understanding-dmv-genie-ai-why-it-sometimes-makes-mistakes"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500/60 dark:text-blue-400/60 hover:text-blue-500 dark:hover:text-blue-400 hover:underline transition-colors"
        >
          Please double-check responses
        </a>
      </span>
    </div>
  );
}