export function extractStepsFromText(text: string): string[] {
  try {
    const lines = text.split('\n');
    // First find the main steps section
    const mainSteps = lines
      .filter(line => /^\d+\.\s/.test(line))
      .map(line => {
        // Extract step title, handle both with and without colon
        const match = line.match(/^\d+\.\s+([^:]+?)(?:\s*:|$)/);
        if (!match) return null;
        
        // Clean up any markdown formatting
        return match[1].replace(/\*\*/g, '').trim();
      })
      .filter(Boolean) as string[];

    return mainSteps.length >= 2 ? mainSteps : [];
  } catch (error) {
    console.error('Error extracting steps:', error);
    return [];
  }
}

export function formatDiagramContent(steps: string[]): string {
  if (!steps?.length) return '';

  const cleanSteps = steps.map(step => {
    // Clean and sanitize step text
    return step
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, ' ')     // Normalize whitespace
      .trim();
  });

  const diagram = [
    'graph TD',
    '%%{',
    '  init: {',
    '    "flowchart": {',
    '      "curve": "basis",',
    '      "padding": 20,',
    '      "nodeSpacing": 50,',
    '      "rankSpacing": 50',
    '    },',
    '    "theme": "base",',
    '    "themeVariables": {',
    '      "primaryColor": "#f8fafc",',
    '      "primaryBorderColor": "#64748b",',
    '      "lineColor": "#64748b",',
    '      "fontSize": "14px"',
    '    }',
    '  }',
    '}%%'
  ];

  // Add nodes with proper escaping
  cleanSteps.forEach((step, i) => {
    const id = String.fromCharCode(65 + i);
    // Wrap text in quotes to handle special characters
    diagram.push(`    ${id}["${step}"]`);
  });

  // Add connections
  cleanSteps.slice(0, -1).forEach((_, i) => {
    const startId = String.fromCharCode(65 + i);
    const endId = String.fromCharCode(65 + i + 1);
    diagram.push(`    ${startId} --> ${endId}`);
  });

  // Add styles
  diagram.push('    classDef default fill:#f8fafc,stroke:#64748b,stroke-width:2px');
  diagram.push('    classDef start fill:#e3f2fd,stroke:#1e88e5,stroke-width:2px');
  diagram.push('    classDef finish fill:#e8f5e9,stroke:#43a047,stroke-width:2px');
  diagram.push('    class A start');

  // Apply finish class to last node
  const lastNodeId = String.fromCharCode(65 + cleanSteps.length - 1);
  diagram.push(`    class ${lastNodeId} finish`);

  return diagram.join('\n');
}