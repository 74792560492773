import React, { useState } from 'react';
import { Volume2, VolumeX, RotateCcw, Printer, Download, Share2, MoreVertical } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { AudioRecorder } from './AudioRecorder';

interface ChatControlsProps {
  autoPlayEnabled: boolean;
  onAutoPlayToggle: () => void;
  onReset: () => void;
  onExport: () => void;
  onShare: () => void;
  onPrint: () => void;
  onAudioComplete: (audioBlob: Blob) => void;
  isProcessingAudio?: boolean;
}

export function ChatControls({
  autoPlayEnabled,
  onAutoPlayToggle,
  onReset,
  onExport,
  onShare,
  onPrint,
  onAudioComplete,
  isProcessingAudio
}: ChatControlsProps) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const canShare = React.useMemo(() => {
    return typeof navigator !== 'undefined' && 
           (navigator.share !== undefined || 
            navigator.clipboard !== undefined);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const menuItems = [
    { icon: Printer, label: 'Print', onClick: onPrint },
    { icon: Download, label: 'Export', onClick: onExport },
    ...(canShare ? [{ icon: Share2, label: 'Share', onClick: onShare }] : []),
    { icon: RotateCcw, label: 'Reset', onClick: onReset },
  ];

  return (
    <div className="flex flex-col gap-2 py-2">
      {/* Auto-play and Voice on same line */}
      <div className="flex items-center gap-2">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onAutoPlayToggle}
          className={`flex-1 inline-flex items-center justify-center gap-2 px-3 py-1.5 rounded-xl transition-colors ${
            autoPlayEnabled
              ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
              : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
          }`}
        >
          {autoPlayEnabled ? <Volume2 size={16} /> : <VolumeX size={16} />}
          <span className="text-sm">Auto-play</span>
        </motion.button>

        <AudioRecorder
          onRecordingComplete={onAudioComplete}
          isProcessing={isProcessingAudio}
          className="flex-1"
        />

        {/* Mobile Menu */}
        <div className="relative md:hidden" ref={menuRef}>
          <button
            onClick={() => setShowMenu(!showMenu)}
            className={`p-1.5 rounded-xl transition-colors ${
              showMenu
                ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                : 'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white'
            }`}
          >
            <MoreVertical size={16} />
          </button>

          <AnimatePresence>
            {showMenu && (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="absolute right-0 bottom-full mb-2 w-48 py-2 bg-white dark:bg-gray-800 rounded-xl shadow-xl z-50"
              >
                {menuItems.map((item) => (
                  <button
                    key={item.label}
                    onClick={() => {
                      item.onClick();
                      setShowMenu(false);
                    }}
                    className="w-full flex items-center gap-3 px-4 py-2 text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                  >
                    <item.icon size={16} />
                    <span>{item.label}</span>
                  </button>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Desktop Controls */}
        <div className="hidden md:flex items-center gap-2">
          {menuItems.map((item) => (
            <motion.button
              key={item.label}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={item.onClick}
              className="p-2 rounded-xl text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
              title={item.label}
            >
              <item.icon size={18} />
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
}