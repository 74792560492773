import React from 'react';

interface AgePickerProps {
  onSelect: (age: number) => void;
}

export function AgePicker({ onSelect }: AgePickerProps) {
  const ageGroups = [
    { label: 'Teen', range: [13, 19] },
    { label: 'Young Adult', range: [20, 29] },
    { label: 'Adult', range: [30, 59] },
    { label: 'Senior', range: [60, 100] },
  ];

  const [selectedGroup, setSelectedGroup] = React.useState<number[]>();

  return (
    <div className="space-y-4 mt-2">
      {!selectedGroup ? (
        <div className="grid grid-cols-2 gap-2">
          {ageGroups.map((group) => (
            <button
              key={group.label}
              onClick={() => setSelectedGroup(group.range)}
              className="px-3 py-2 text-sm rounded-xl bg-white dark:bg-gray-900 border border-gray-200/50 dark:border-gray-800/50 text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 hover:border-gray-300 dark:hover:border-gray-700 transition-all duration-200 shadow-sm hover:shadow-md"
            >
              <div className="font-medium">{group.label}</div>
              <div className="text-xs text-gray-500 dark:text-gray-400">
                {group.range[0]}-{group.range[1]} years
              </div>
            </button>
          ))}
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-between mb-2">
            <button
              onClick={() => setSelectedGroup(undefined)}
              className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            >
              ← Back to age groups
            </button>
          </div>
          <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 gap-2">
            {Array.from(
              { length: selectedGroup[1] - selectedGroup[0] + 1 },
              (_, i) => selectedGroup[0] + i
            ).map((age) => (
              <button
                key={age}
                onClick={() => onSelect(age)}
                className="px-3 py-2 text-sm rounded-xl bg-white dark:bg-gray-900 border border-gray-200/50 dark:border-gray-800/50 text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 hover:border-gray-300 dark:hover:border-gray-700 transition-all duration-200 shadow-sm hover:shadow-md"
              >
                {age}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}