import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Bot, CheckCircle, ArrowRight } from 'lucide-react';

interface DemoSectionProps {
  onDismiss: () => void;
}

export function DemoSection({ onDismiss }: DemoSectionProps) {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-b from-white to-gray-50" />
      
      <div className="relative max-w-[1080px] mx-auto px-4 md:px-8 py-16 md:py-24">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-semibold text-gray-900 mb-4">
            How It Works
          </h2>
          <p className="text-xl text-gray-600">
            Get instant DMV assistance in three simple steps
          </p>
        </div>

        <div className="relative">
          <div className="grid md:grid-cols-3 gap-8 md:gap-12 relative z-10">
            {/* Step 1 */}
            <div className="text-center">
              <div className="inline-flex p-4 rounded-2xl bg-blue-50 text-blue-600 mb-4">
                <MessageSquare size={24} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                1. Ask Your Question
              </h3>
              <p className="text-gray-600 mb-4">
                Type your DMV-related question or use voice input
              </p>
              <div className="p-4 bg-blue-50/50 rounded-xl text-sm text-gray-600">
                "How do I renew my driver's license?"
              </div>
            </div>

            {/* Step 2 */}
            <div className="text-center">
              <div className="inline-flex p-4 rounded-2xl bg-purple-50 text-purple-600 mb-4">
                <Bot size={24} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                2. AI Assistant Processes
              </h3>
              <p className="text-gray-600 mb-4">
                Our AI analyzes your question and finds state-specific information
              </p>
              <motion.div 
                className="p-4 bg-purple-50/50 rounded-xl"
                animate={{
                  scale: [1, 1.02, 1],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <Bot size={24} className="text-purple-600 mx-auto" />
              </motion.div>
            </div>

            {/* Step 3 */}
            <div className="text-center">
              <div className="inline-flex p-4 rounded-2xl bg-green-50 text-green-600 mb-4">
                <CheckCircle size={24} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                3. Get Instant Answer
              </h3>
              <p className="text-gray-600 mb-4">
                Receive clear, accurate guidance tailored to your needs
              </p>
              <div className="relative">
                <motion.div 
                  className="absolute inset-0 bg-green-400/20 rounded-xl blur-xl"
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.5, 0.8, 0.5],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
                <motion.div 
                  className="relative p-4 bg-white rounded-xl shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <motion.div 
                    className="h-2 w-24 bg-green-500 rounded-full mb-2"
                    initial={{ width: 0 }}
                    animate={{ width: 96 }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    }}
                  />
                  <motion.div 
                    className="h-2 w-16 bg-green-200 rounded-full"
                    initial={{ width: 0 }}
                    animate={{ width: 64 }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                      delay: 0.2,
                    }}
                  />
                </motion.div>
              </div>
            </div>
          </div>

          {/* Connection Lines */}
          <div className="absolute inset-0 pointer-events-none z-0">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1200 400"
              fill="none"
              preserveAspectRatio="xMidYMid meet"
              className="absolute inset-0 hidden md:block"
              style={{ top: '20%' }}
            >
              {/* Step 1 to Step 2 */}
              <motion.path
                d="M400 100 L600 100"
                stroke="rgb(59 130 246 / 0.2)"
                strokeWidth="2"
                strokeDasharray="6 6"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1.5,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                  repeatDelay: 1
                }}
              />

              {/* Step 2 to Step 3 */}
              <motion.path
                d="M700 100 L900 100"
                stroke="rgb(59 130 246 / 0.2)"
                strokeWidth="2"
                strokeDasharray="6 6"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1.5,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                  repeatDelay: 1,
                  delay: 0.5
                }}
              />

              {/* Arrow */}
              <motion.path
                d="M890 90 L900 100 L890 110"
                stroke="rgb(59 130 246 / 0.2)"
                strokeWidth="2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2 }}
              />
            </svg>
          </div>
        </div>

        {/* Try Demo Button */}
        <div className="mt-12 md:mt-24 text-center relative z-20">
          <motion.button
            onClick={onDismiss}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-8 py-3 bg-blue-600 text-white rounded-full font-medium hover:bg-blue-700 transition-colors flex items-center gap-2 shadow-lg mx-auto"
          >
            <MessageSquare size={20} />
            Start Chat Assistant <ArrowRight size={16} />
          </motion.button>
        </div>
      </div>
    </div>
  );
}