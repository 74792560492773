import React, { useState, useEffect } from 'react';
import { Send } from 'lucide-react';
import { motion } from 'framer-motion';

interface ChatInputProps {
  onSend: (message: string) => void;
  disabled?: boolean;
}

export function ChatInput({ onSend, disabled }: ChatInputProps) {
  const [input, setInput] = useState('');
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const maxChars = 500;

  useEffect(() => {
    // Add meta viewport tag to prevent zooming
    const metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    metaViewport.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
    document.head.appendChild(metaViewport);

    const handleResize = () => {
      const height = window.visualViewport?.height || window.innerHeight;
      const keyboardVisible = window.innerHeight - height > 100;
      setIsKeyboardVisible(keyboardVisible);
      
      // Adjust the container's padding when keyboard is visible
      if (keyboardVisible) {
        document.body.style.paddingBottom = '120px';
        // Scroll to the bottom to ensure input is visible
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        document.body.style.paddingBottom = '';
      }
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
      window.visualViewport.addEventListener('scroll', handleResize);
    } else {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
        window.visualViewport.removeEventListener('scroll', handleResize);
      } else {
        window.removeEventListener('resize', handleResize);
      }
      document.head.removeChild(metaViewport);
      document.body.style.paddingBottom = '';
    };
  }, []);

  useEffect(() => {
    setCharCount(input.length);
  }, [input]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim() && !disabled) {
      onSend(input.trim());
      setInput('');
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <motion.form
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      onSubmit={handleSubmit}
      className={`${isKeyboardVisible ? 'fixed bottom-0 left-0 right-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-xl p-4' : ''}`}
    >
      <div className="relative max-w-3xl mx-auto">
        <textarea
          value={input}
          onChange={(e) => {
            if (e.target.value.length <= maxChars) {
              setInput(e.target.value);
            }
          }}
          onKeyDown={handleKeyDown}
          placeholder="Ask anything about DMV services..."
          rows={1}
          disabled={disabled}
          aria-label="Chat input"
          className="w-full resize-none rounded-2xl border border-gray-200/50 dark:border-gray-800/50 bg-white/50 dark:bg-gray-900/50 backdrop-blur-xl px-4 py-3.5 text-sm text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/20 disabled:opacity-50 transition-all duration-200 pr-12"
        />
        {input.length > 0 && (
          <div className="absolute right-14 bottom-1 text-xs text-gray-400">
            {charCount}/{maxChars}
          </div>
        )}
        <motion.button
          type="submit"
          disabled={disabled || !input.trim()}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ 
            scale: input.trim() ? 1 : 0.9,
            opacity: input.trim() ? 1 : 0.7,
          }}
          transition={{ type: "spring", stiffness: 400, damping: 25 }}
          className="absolute right-2 top-1/2 -translate-y-1/2 h-[38px] w-[38px] flex-shrink-0 flex items-center justify-center rounded-xl bg-blue-600 dark:bg-blue-500 text-white hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500/50 dark:focus:ring-blue-400/50 focus:ring-offset-2 dark:focus:ring-offset-gray-900 disabled:opacity-50 transition-colors shadow-lg hover:shadow-xl"
          aria-label="Send message"
        >
          <Send size={18} className="-ml-0.5" />
        </motion.button>
      </div>
    </motion.form>
  );
}