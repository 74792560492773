import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

export function FAQ() {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const faqs = [
    {
      question: "Is this an official DMV website?",
      answer: "No, DMV Plus is not affiliated with any government agency. We are an AI-powered assistant designed to help you navigate DMV services and requirements more easily."
    },
    {
      question: "How accurate is the information provided?",
      answer: "Our AI assistant provides information based on official DMV sources and is regularly updated. However, requirements can vary by state and change over time. We recommend verifying critical information with your local DMV."
    },
    {
      question: "Is my personal information secure?",
      answer: "Yes, we take privacy seriously. We don't store any personal information and our chat sessions are encrypted and temporary."
    },
    {
      question: "What services can DMV Plus help me with?",
      answer: "We can help with driver's licenses, vehicle registration, permits, REAL ID requirements, finding DMV locations, and understanding state-specific requirements."
    },
    {
      question: "How much does it cost to use DMV Plus?",
      answer: "DMV Plus is completely free to use. There are no hidden fees or subscriptions required."
    }
  ];

  return (
    <div className="max-w-[1080px] mx-auto px-8 py-24">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-gray-900 mb-4">
          Frequently Asked Questions
        </h2>
        <p className="text-xl text-gray-600">
          Get answers to common questions about DMV Plus
        </p>
      </div>

      <div className="max-w-2xl mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="border border-gray-200 rounded-xl overflow-hidden"
          >
            <button
              onClick={() => setOpenItem(openItem === index ? null : index)}
              className="w-full flex items-center justify-between p-6 text-left bg-white hover:bg-gray-50 transition-colors"
            >
              <span className="font-medium text-gray-900">{faq.question}</span>
              <motion.div
                animate={{ rotate: openItem === index ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronDown size={20} className="text-gray-500" />
              </motion.div>
            </button>
            <AnimatePresence>
              {openItem === index && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="border-t border-gray-200 bg-gray-50"
                >
                  <div className="p-6 text-gray-600">
                    {faq.answer}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
}